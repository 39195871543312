import React, { useCallback, useState } from "react";
import { Drawer } from "@material-ui/core";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as GreenDot } from "../../assets/img/icons-new/general/green-dot/green-dot.svg";
import { ReactComponent as ChevronDown } from "../../assets/img/icons-new/Chevron/chevron-down/chevron-down-black.svg";
import { ReactComponent as MinimizeSvg } from "../../assets/img/icons-new/general/minimize/minimize-black.svg";
import { ReactComponent as CallIcon } from "../../assets/img/icons-new/voice-tab/all-voice-message/all-voice-message-white.svg";
import Avatar from "../Avatar";
import VoiceDialpad from "../hub/Voice/VoiceDialpad";
import SearchBox from "../hub/HelperComponents/SearchBox";
import TooltipWhenOverflow from "../hub/HelperComponents/TooltipWhenOverflow";
import MessageCircleButton from "../hub/HelperComponents/MessageCircleButton";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import KeypadCircleButton from "../hub/HelperComponents/KeypadCircleButton";
import LongNamesModal from "./LongNamesModal";
import { formatPhoneNumber, isNumber } from "../../helpers";
import HoldOrUnHoldCallButton from "../hub/HoldOrUnHoldCallButton/HoldOrUnHoldCallButton";
import { useCallsStore } from "../../utils/hooks/ReduxHooks/callsStore";

const IncomingCallDrawer = (props) => {
  const {
    show,
    closeDrawer,
    contactData,
    name,
    number,
    duration,
    creditSpent,
    declineCall,
  } = props;

  const [longNameModalContent, setLongNameModalContent] = useState("");
  const [queryState, setQueryState] = useState("");
  const [showLongNameModal, setShowLongNameModal] = useState(false);
  const [showKeypad, setShowKeypad] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  // Redux store
  const { calls } = useCallsStore();
  const { isCallOnHold } = calls;

  // Routers
  const location = useLocation();
  const history = useHistory();

  // Breakpoint
  const breakpoint = useBreakpoint();

  const toggleLongNameModal = useCallback(() => {
    setShowLongNameModal(!showLongNameModal);
  }, [showLongNameModal]);

  const handleLongName = useCallback(
    (name) => () => {
      if (window.innerWidth < 600 && name?.length >= 16) {
        setLongNameModalContent(name);
        toggleLongNameModal();
      }
    },
    [toggleLongNameModal]
  );

  const onClickMessageBtn = useCallback(() => {
    if (contactData?.ContactId) {
      history.push(`/hub/messages/contact/${contactData?.ContactId}`);
      if (isMdOrBelowBreakpoint(breakpoint)) {
        closeDrawer();
      }
    }
  }, [breakpoint, closeDrawer, contactData, history]);

  const onClickedKeypadBtn = useCallback(() => {
    setShowKeypad(true);
  }, []);

  const handleQueryChange = useCallback((event) => {
    setQueryState(event.target.value);
  }, []);

  const clearQuery = useCallback(() => {
    setQueryState("");
  }, []);

  const closeDialpad = useCallback(() => {
    setShowKeypad(false);
  }, []);

  const onClickDigit = useCallback(
    (digit) => {
      setQueryState(queryState + digit);
    },
    [queryState]
  );

  const onClickBackSpace = useCallback(() => {
    setQueryState(queryState.slice(0, -1));
  }, [queryState]);

  const minimizeSideDrawer = useCallback(() => {
    setIsMinimized(true);
    setTimeout(() => {
      closeDrawer();
      setIsMinimized(false); // Optionally close the drawer after animation
    }, 300); // Match this with
  }, [closeDrawer]);

  return (
    <Drawer
      classes={{
        root: `voice-dialer-main-container making-call`,
        paper: `voice-dialer-paper ${
          isMinimized ? "minimized-effect" : "maximized-effect"
        }`,
      }}
      variant="permanent"
      anchor="right"
      open={show}
      onClose={closeDrawer}
      hideBackdrop={true}
    >
      <div className="themed-modal h-100">
        <div className="modal-header">
          <div
            className="close-icon cursor-pointer"
            data-dismiss="modal"
            data-tooltip="tooltip"
            data-placement="top"
            title="Minimize"
            onClick={minimizeSideDrawer}
          >
            <MinimizeSvg width={35} height={35} />
          </div>
        </div>
        <div className="modal-body p-0 flexer-col h-100">
          <Avatar
            isGroup={false}
            firstName={contactData?.ContactFirstName}
            lastName={contactData?.ContactLastName}
            email={contactData?.ContactEmail}
            bgColor={contactData?.ContactColor}
            size={75}
          />

          <div className="contact-info p-0">
            <span>
              <GreenDot className="mr-1" /> On Call (Incoming)
            </span>
            <TooltipWhenOverflow maxWidth={270} text={name}>
              <span onClick={handleLongName(name)}>{name}</span>
            </TooltipWhenOverflow>
            {!isNumber(name) && (
              <span className="number">{formatPhoneNumber(number)}</span>
            )}
            <span className="call-timer">{duration}</span>
          </div>

          {!showKeypad && (
            <>
              <div className="credit-info">
                <div className="flexer-col">
                  <span className="mr-3 d-block" style={{ fontWeight: 400 }}>
                    You spent {creditSpent} credits
                  </span>
                  <span>2 credits/min</span>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center justify-content-around mt-4">
                <MessageCircleButton
                  active={location?.pathname?.includes("/hub/messages")}
                  onClick={onClickMessageBtn}
                  disabled={!contactData?.ContactId}
                />
                <KeypadCircleButton
                  disabled={isCallOnHold}
                  onClick={onClickedKeypadBtn}
                />
              </div>
              <div className="w-100 d-flex align-items-center justify-content-around mt-4 mr-4">
                <HoldOrUnHoldCallButton
                  callSID={contactData?.ParentCallSid || ""}
                  disabled={!contactData?.ParentCallSid}
                />
                <span />
              </div>
              <a href="#void" className="decline" onClick={declineCall}>
                <CallIcon />
              </a>
            </>
          )}

          {showKeypad && (
            <div>
              <div className="search-wrapper-outer d-flex border-top pt-1 pb-1">
                <SearchBox
                  placeholder={""}
                  value={queryState}
                  onChange={handleQueryChange}
                  onClickClear={clearQuery}
                  className={"voice-dialer-input-box w-100"}
                  autoFocus={true}
                />
                <ChevronDown
                  onClick={closeDialpad}
                  height={35}
                  width={35}
                  className="cursor-pointer dialpad-hidden-icon"
                />
              </div>
              <VoiceDialpad
                onlyDisplayDeclineBtn={true}
                onClickDeclineBtn={declineCall}
                withBorderTop={false}
                showBackSpaceBtn={queryState.trim()}
                onClickDigit={onClickDigit}
                onClickBackSpace={onClickBackSpace}
              />
            </div>
          )}
        </div>
      </div>
      {showLongNameModal && (
        <LongNamesModal
          show={showLongNameModal}
          onClose={toggleLongNameModal}
          modalBodyContent={longNameModalContent}
        />
      )}
    </Drawer>
  );
};

IncomingCallDrawer.propTypes = {
  show: PropTypes.bool,
  isMinimized: PropTypes.bool,
  closeDrawer: PropTypes.func,
  contactData: PropTypes.object,
  name: PropTypes.string,
  number: PropTypes.string,
  duration: PropTypes.string,
  creditSpent: PropTypes.number,
  declineCall: PropTypes.func,
};

IncomingCallDrawer.defaultProps = {
  show: false,
  isMinimized: false,
  closeDrawer: () => {},
  contactData: {},
  name: "",
  number: "",
  duration: "",
  creditSpent: 0,
  declineCall: () => {},
};

export default IncomingCallDrawer;
