import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ModalWrapper from "./Helpers/ModalWrapper";
import Avatar from "../Avatar";
import { ReactComponent as CallDeclineWhite } from "../../assets/img/icons-new/call-decline/call-decline.svg";
import { ReactComponent as CallWhiteIcon } from "../../assets/img/icons-new/other/capabilities/call-white.svg";
import { ReactComponent as CollapseIcon } from "../../assets/img/icons-new/modal-collapse/modal-collapse.svg";
import { ReactComponent as CallIcon } from "../../assets/img/icons-new/other/capabilities/call-gray.svg";
import ButtonWithSpinner from "../hub/HelperComponents/ButtonWithSpinner";
import { useTwilioStore } from "../../utils/hooks/ReduxHooks/twilioStore";
import { CANCELLED, DEFAULT_CREDITS } from "../../utils/twilioHelpers";
import { LightTooltip, formatPhoneNumber, isNumber } from "../../helpers";
import {
  INCOMING_CALL_MICROPHONE_PERMISSIONS_NOT_GRANTED,
  OUT_OF_CREDIT_TOOLTIP,
} from "../../utils/callConstants";
import OutOfCreditAlert from "../hub/HubThreadMessageForm/AlertNotifier/OutOfCreditAlert";

const IncomingCallModal = (props) => {
  const {
    show,
    closeModal,
    declineIncomingCalls,
    acceptIncomingCalls,
    incomingCallFrom,
    customParameters,
    number,
    withoutMicrophonePermissions,
    isOutOfCredits,
  } = props;

  // Redux store
  const { twilio } = useTwilioStore();
  const { incomingCallStatus } = twilio;

  const renderDisabledTooltip = useMemo(() => {
    if (isOutOfCredits) {
      return OUT_OF_CREDIT_TOOLTIP;
    } else if (withoutMicrophonePermissions) {
      return INCOMING_CALL_MICROPHONE_PERMISSIONS_NOT_GRANTED;
    }

    return "";
  }, [isOutOfCredits, withoutMicrophonePermissions]);

  const renderMessage = useMemo(() => {
    if (incomingCallStatus === CANCELLED) {
      return <span className="incoming-modal-light-text">Call Ended</span>;
    }
    return (
      <span className="incoming-modal-light-text">
        <CallIcon /> Incoming Call
      </span>
    );
  }, [incomingCallStatus]);

  return (
    <ModalWrapper
      id="IncomingCallModal"
      show={show}
      closeModal={closeModal}
      className="incoming-call-modal"
      shakeModal={() => {}}
      hasCloseBtn={false}
    >
      <div className="incoming-call-modal-container">
        <div className="d-flex align-items-center justify-content-end">
          <CollapseIcon
            className="collapse-icon cursor-pointer"
            onClick={closeModal}
            height={45}
            width={45}
          />
        </div>
        <div className="flexer-col">
          <div className="flexer-row">
            <Avatar
              firstName={customParameters?.ContactFirstName}
              lastName={customParameters?.ContactLastName}
              email={customParameters?.ContactEmail}
              bgColor={customParameters?.ContactColor}
              className="big-avatar mr-2"
            />{" "}
            <span>
              <div className="caller-name">{incomingCallFrom || "Unknown"}</div>
              {!isNumber(incomingCallFrom) && (
                <div className="caller-number">{formatPhoneNumber(number)}</div>
              )}
            </span>
          </div>
          <div className="flexer-row mt-3">{renderMessage}</div>
          <div
            className={clsx("w-100", {
              "mt-5": !withoutMicrophonePermissions,
              "mt-3": withoutMicrophonePermissions,
            })}
          >
            {withoutMicrophonePermissions && (
              <p className="microphone-error-message text-center">
                Please enable microphone access in your browser <br /> to accept
                the call.
              </p>
            )}
            <div className="d-flex align-items-center justify-content-center">
              <LightTooltip title={renderDisabledTooltip}>
                <span>
                  <ButtonWithSpinner
                    onClick={acceptIncomingCalls}
                    className={"mr-5 accept-call-btn"}
                    disabled={withoutMicrophonePermissions || isOutOfCredits}
                  >
                    <CallWhiteIcon className="mr-1" /> Accept
                  </ButtonWithSpinner>
                </span>
              </LightTooltip>
              <ButtonWithSpinner
                onClick={declineIncomingCalls}
                className={"decline-call-btn"}
              >
                <CallDeclineWhite className="mr-1 " />
                Decline
              </ButtonWithSpinner>
            </div>
            {isOutOfCredits && <OutOfCreditAlert onClickBtn={closeModal} />}
          </div>

          <div className="incoming-modal-dark-small-text flexer-row mt-4 mb-4">
            {DEFAULT_CREDITS} Credits / Min
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

IncomingCallModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  callReceivingNumberObj: PropTypes.object,
  customParameters: PropTypes.object,
  incomingCallFrom: PropTypes.string,
  number: PropTypes.string,
  isOutOfCredits: PropTypes.bool,
};

IncomingCallModal.defaultProps = {
  callReceivingNumberObj: {},
  customParameters: {},
  incomingCallFrom: "",
  number: "",
  isOutOfCredits: false,
};

export default IncomingCallModal;
