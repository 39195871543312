import {
  ADMIN_BILLING_ROUTE,
  ADMIN_INTEGRATIONS_ROUTE,
  ADMIN_SECURITY_ROUTE,
  CAMPAIGNS_ROUTE,
  QR_CODE_ROUTE,
  SHORTCODE_KEYWORDS_ROUTE,
  WEB_WIDGETS_ROUTE,
  ANALYTICS_LOGS_LEGACY_ROUTE,
  ANALYTICS_REPORTS_ROUTE,
  ADMIN_NUMBERS_VOICE_BROADCAST,
  ADMIN_NUMBERS_NUMBER_LANDLINE,
  ADMIN_NUMBERS_CHECK_STATUS,
  ADMIN_NUMBERS_HOSTED_BRIDGE,
  ADMIN_NUMBERS_TEXT_SMS,
  ANALYTICS_LOGS_NEW_ROUTE,
  ADMIN_ENABLE_SHORTCODE_AND_TOLLFREE,
} from "../components/Menus/routes";

export const DEFAULT_COUNTRY_ID = 226;
export const DEFAULT_PHONE_CODE = 1;

// TOKENS
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const APP_SHARED_SESSION = "appSharedSession";
export const APP_SHARED_SESSION_COOKIE = "app_shared_session";

// Localstorage
export const RELOAD_COUNT = "reloadCount";

// Check number status
export const ACTIVE_STATUS = "active";

export const PROCESSING_STATUS = "processing";
export const PROCESSING_PENDING_LETTER = "pending-letter";
export const PROCESSING_UNDER_REVIEW = "under-review";
export const PROCESSING_CARRIER = "carrier";

export const ACTION_REQUIRED_STATUS = "action-required";
export const ACTION_REQUIRED_HOSTING_FAILED = "hosting-failed";
export const ACTION_REQUIRED_CANCELLED = "cancelled";
export const ACTION_REQUIRED_EXPIRED = "Expired";

// Greetings status
export const GREETING_DRAFT_STATUS = "Draft";
export const GREETING_PUBLISHED_STATUS = "Published";

// Message
export const MAX_MESSAGE_LENGTH = 1600;

// Filter names
export const FILTER_NAMES = {
  blocked: "Blocked",
  unsubscribed: "Unsubscribed",
  "opted-out": "Opted Out",
  "do-not-call": "Do Not Call",
  duplicates: "Duplicates",
  voip: "Local VoIP",
  landline: "Landline",
  mobile: "Mobile",
  "number-not-checked": "Unchecked",
  "unformatted-number": "Format Error",
  archived: "Archived",
};

// Signature types
export const SHORT_CODE_SIGNATURE = "short_code_signature";
export const TOLL_FREE_SIGNATURE = "toll_free_signature";
export const STANDARD_TEXT_SIGNATURE = "standard_text_signature";
export const GROUP_TEXT_SIGNATURE = "group_text_signature";
export const PERSONAL_HIGH_THROUGHPUT_TOLL_FREE =
  "personal_high_throughput_toll_free_signature";
export const CUSTOM_SIGNATURE = "custom_signature";

export const UNASSIGNED_NUMBER = "unassigned";

export const API_TIMEOUT = 8000;

export const signatureTypeToText = {
  [`${STANDARD_TEXT_SIGNATURE}`]: "Default Standard Signature (One-to-One)",
  [`${GROUP_TEXT_SIGNATURE}`]: "Default Standard Signature (Group Texting)",
  [`${TOLL_FREE_SIGNATURE}`]: "Default Toll Free Reprocessing Signature",
  [`${SHORT_CODE_SIGNATURE}`]: "Default Short Code Signature",
  [`${PERSONAL_HIGH_THROUGHPUT_TOLL_FREE}`]:
    "Default High Throughput Toll Free",
};

export const signatureTypeToTextParentheses = {
  [`${STANDARD_TEXT_SIGNATURE}`]: "(One-to-One)",
  [`${GROUP_TEXT_SIGNATURE}`]: "(Group Texting)",
};

export const textForShortCodeNotice = [
  "STOP to Opt Out",
  "[Org Short Name]",
  "[User Short Name]",
];

export const textForShortCodeNotices = {
  stoppedOut: ["STOP to Opt Out"],
  orgShortName: ["[Org Short Name]", "[Org Full Name]"],
  userShortName: [
    "[User Short Name]",
    "[User Full Name]",
    "[User First Name]",
    "[User Last Name]",
  ],
};

export const ASSIGNED_NUMBER_ALERT_LINK =
  "/hub/menus/filter/settings/admin-settings/submenu/admin-numbers-voice-broadcast";

export const RETRY_RELOAD_CHUNK_ATTEMPTS = 3;

export const HOLD_CONTACTS_FILTERS = [
  "hold-all-unread",
  "hold-all",
  "hold-breeze",
  "hold-ccb",
  "hold-elvanto",
  "hold-mc",
  "hold-pco",
  "hold-rock",
];

export const DRAFTS_LOCAL_STORAGE_PREFIX = "message";

export const BILLING_PRIVILIGES_MESSAGE =
  "User Can Manage/Pay With Credit Card(s) On File";
export const PEOPLE_MANAGEMENT_MESSAGE =
  "User Has People Management Privileges";

export const EMBEDDED_FILTER_SCREENS_ROUTES = [
  CAMPAIGNS_ROUTE,
  WEB_WIDGETS_ROUTE,
  SHORTCODE_KEYWORDS_ROUTE,
  QR_CODE_ROUTE,
];

export const EMBEDDED_FILTER_SCREENS_ROUTES_WITHOUT_CAMPAIGNS_ROUTE = [
  WEB_WIDGETS_ROUTE,
  SHORTCODE_KEYWORDS_ROUTE,
  QR_CODE_ROUTE,
];

export const EMBEDDED_SUBMENU_SCREENS_ROUTES = [
  ADMIN_BILLING_ROUTE,
  ADMIN_INTEGRATIONS_ROUTE,
  ADMIN_SECURITY_ROUTE,
  ADMIN_NUMBERS_VOICE_BROADCAST,
  ADMIN_NUMBERS_NUMBER_LANDLINE,
  ADMIN_NUMBERS_CHECK_STATUS,
  ADMIN_NUMBERS_HOSTED_BRIDGE,
  ADMIN_ENABLE_SHORTCODE_AND_TOLLFREE,
  ADMIN_NUMBERS_TEXT_SMS,
  ANALYTICS_LOGS_LEGACY_ROUTE,
  ANALYTICS_LOGS_NEW_ROUTE,
  ANALYTICS_REPORTS_ROUTE,
];

export const DEFAULT_LONG_PRESS_OPTIONS = {
  shouldPreventDefault: true,
  delay: 500,
};

export const GLOBAL_THEME = {
  LIGHT: "light",
  HYBRID: "hybrid",
  DARK: "dark",
};

export const FIRST_LOAD_DASHBOARD = "first-load-dashboard";
export const FORCE_DEFAULT_LAYOUT_DASHBOARD = "force-default-layout-dashboard";
export const PROCESS_QUEUE = "process-queue";
export const SAVED_SEARCHES = "saved-search";
export const CCB_GROUPS = "group";
export const CCB_CAMPUS = "campus";
export const CCB = "ccb";
export const BREEZE = "breeze";
export const ELVANTO = "elvanto";
export const PCO = "pco";
export const MC = "mc";
export const ROCK = "rock";
export const MAILCHIMP = "mailchimp";
export const PLANNING_CENTER = "planning-center";

// Dashboard  default layout
export const DEFAULT_DASHBOARD_LAYOUT = [
  { i: "credit-balance", x: 2, y: 0, w: 1, h: 1, static: true },
  { i: "time-zone", x: 3, y: 0, w: 1, h: 1, static: true },
  { i: "message-calls", x: 2, y: 1, w: 2, h: 3, static: true },
  // { i: "credits-used", x: 0, y: 4, w: 2, h: 4, static: true },
  { i: "get-started", x: 2, y: 5, w: 2, h: 3, static: true },
  // { i: "get-started-learn", x: 3, y: 2, w: 2, h: 1, static: true },
  { i: "my-organization", x: 0, y: 0, w: 2, h: 3, static: true },
  { i: "sitemap", x: 0, y: 3, w: 2, h: 5.3, static: true },
  { i: "credit-summary", x: 2, y: 0, w: 1, h: 2, static: true },
];

// Tab Embed Routes
export const AUTO_REPLY_ROUTE = "auto-reply";
export const IMPORT_CONTACTS_ROUTE = "import-contacts";
export const DATA_CAPTURE_ROUTE = "data-capture";
export const CREATE_POLL_ROUTE = "create-poll";
export const SMS_BIRTHDAY_ROUTE = "sms-birthday";
export const CREATE_CONTEST_ROUTE = "create-contest";
export const AUTOMATED_MESSAGE_ROUTE = "automated-message";

// Threads sent filters
export const THREADS_SENT_FILTER = [
  "sent-contacts",
  "sent-groups",
  "named",
  "unnamed",
];

// Events
export const USER_LOGOUT = "user-logout";
export const MESSAGE = "message";
export const OPEN_INTERCOM = "open-intercom";
export const APP_LOADING = "app-loading";
export const DEFAULT_APP_VERSION = "default-app-version";
export const ASK_CAMERA_PERMISSION = "camera-permission";
export const OPT_OUT_BIOMETRIC = "opt-out-biometric";
export const OPT_IN_BIOMETRIC = "opt-in-biometric";
export const REQUEST_MICROPHONE_ACCESS = "request-microphone-access";

// Broadcast channel events
export const SESSION_STARTED = "session-started";
export const PAYMENT_SUCCESSFUL = "payment-successful";

// Group tab view types
export const GROUPS_FILTER_ALL_INTEGRATIONS = "all-integrations";

// Thread types
export const CONTACT = "contact";
export const GROUP = "group";

// Tooltip
export const FEATURE_ONLY_AVAILABLE_IN_US =
  "This feature is only available to U.S region";
export const NO_BILLING_PRIVILEGES = "You do not have billing privileges";

// Display Mode
export const MOBILE = "mobile";
export const NORMAL = "normal";
export const DISPLAY_MODE_HELPER_TEXT =
  "Manage default modes in App Settings > Display";

// Numbers cost types
export const NUMBER_COST_TYPES = [
  "monthly-credits",
  "yearly-credits",
  "monthly-cash",
  "yearly-cash",
];

// External apps
export const INTEGRATION_APPS = [
  "all",
  "ccb",
  "pco",
  "mc",
  "elvanto",
  "breeze",
  "rock",
];

//V3 - Mobile Application Link
export const IOS_CURRENT_APPSTORE_LINK =
  "https://apps.apple.com/us/app/pastorsline/id1660782782";
export const ANDROID_CURRENT_PLAYSTORE_LINK =
  "https://play.google.com/store/apps/details?id=com.pastorsline.mobile.v3";

export const DEV_LOGIN_SERVICE_TOKEN = "qmhD#914N";
