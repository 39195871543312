import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as contactActions from "../../../actions/contacts";

export function useContactsStore() {
  const dispatch = useDispatch();
  const _contacts = useSelector((store) => store.contacts);

  const setPeopleFilter = useCallback(
    (filter) => dispatch(contactActions.setFilter(filter)),
    [dispatch]
  );

  const fetchCounts = useCallback(
    (companyId, fields) =>
      dispatch(contactActions.fetchCounts(companyId, fields)),
    [dispatch]
  );

  const markAsReadAllUnresolvedConflict = useCallback(
    (body) => dispatch(contactActions.markAsReadAllUnresolvedConflict(body)),
    [dispatch]
  );

  const fetchContacts = useCallback(
    (companyId, page, orderBy) =>
      dispatch(contactActions.fetchContacts(companyId, page, orderBy)),
    [dispatch]
  );

  const fetchFilteredContacts = useCallback(
    (companyId, query, countryId, countryCode, filter, page, orderBy) =>
      dispatch(
        contactActions.fetchFilteredContacts(
          companyId,
          query,
          countryId,
          countryCode,
          filter,
          page,
          orderBy
        )
      ),
    [dispatch]
  );

  const deleteContact = useCallback(
    (companyId, contactId, groupId) =>
      dispatch(contactActions.deleteContact(companyId, contactId, groupId)),
    [dispatch]
  );

  const groupRHSSelectionCleared = useCallback(
    (selection) => dispatch(contactActions.groupRHSSelectionCleared(selection)),
    [dispatch]
  );

  const addQuickContact = useCallback(
    (companyId, contactData) =>
      dispatch(contactActions.addQuickContact(companyId, contactData)),
    [dispatch]
  );

  const clearAddQuick = useCallback(
    () => dispatch(contactActions.clearAddQuick()),
    [dispatch]
  );

  const clearPatchContactGroups = useCallback(
    () => dispatch(contactActions.clearPatchContactGroups()),
    [dispatch]
  );

  const editContactField = useCallback(
    (companyId, contactId, contactData, requestId) =>
      dispatch(
        contactActions.editContactField(
          companyId,
          contactId,
          contactData,
          requestId
        )
      ),
    [dispatch]
  );

  const patchContactTags = useCallback(
    (companyId, contactId, tagIds) =>
      dispatch(contactActions.patchContactTags(companyId, contactId, tagIds)),
    [dispatch]
  );

  const resetEditContactStatus = useCallback(
    (requestId) => dispatch(contactActions.resetEditContactStatus(requestId)),
    [dispatch]
  );

  const patchContactAmCampaigns = useCallback(
    (companyId, contactId, campaignIds) =>
      dispatch(
        contactActions.patchContactAmCampaigns(
          companyId,
          contactId,
          campaignIds
        )
      ),
    [dispatch]
  );

  const markAsReadUnresolvedConflict = useCallback(
    (body, contactId) =>
      dispatch(contactActions.markAsReadUnresolvedConflict(body, contactId)),
    [dispatch]
  );

  const patchContactGroups = useCallback(
    (companyId, contactId, addedGroupIds, removedGroupIds) =>
      dispatch(
        contactActions.patchContactGroups(
          companyId,
          contactId,
          addedGroupIds,
          removedGroupIds
        )
      ),
    [dispatch]
  );

  const addSnackData = useCallback(
    (data, entity) => dispatch(contactActions.addSnackData(data, entity)),
    [dispatch]
  );

  return {
    contacts: _contacts,
    setPeopleFilter,
    fetchCounts,
    markAsReadAllUnresolvedConflict,
    fetchContacts,
    fetchFilteredContacts,
    deleteContact,
    groupRHSSelectionCleared,
    addQuickContact,
    clearAddQuick,
    clearPatchContactGroups,
    editContactField,
    patchContactTags,
    resetEditContactStatus,
    patchContactAmCampaigns,
    markAsReadUnresolvedConflict,
    patchContactGroups,
    addSnackData,
  };
}

export default useContactsStore;
