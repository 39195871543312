import React from "react";
import { getPrimaryUrl } from "../../helpers";
import AxiosConfig from "../../AxiosConfig";

const LegacyStartSession = () => {
  return (
    <iframe
      src={getPrimaryUrl(`users/startSession?token=${AxiosConfig._token}}`)}
      title="start session"
      width={0}
      height={0}
    ></iframe>
  );
};

export default LegacyStartSession;
