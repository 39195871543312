import { resetFetchContactsRequests } from "../actions/contacts";
import {
  setBlockedContact,
  setDoNotCallContact,
  setResetDoNotCallContact,
  setSubscribeContact,
  setUnlockedContact,
  setUnsubscribeContact,
  softDeleteContactsMapper,
  restoreDeletedContactsMapper,
  setMarkAsFavoriteContact,
  setMarkAsUnfavoriteContact,
} from "../helpers";

const fromEntries = require("fromentries");

const deleteRestoreBasedOnAction = (
  state,
  actionContactIds,
  isUndo,
  actionName,
  isPeopleOnTableView = false
) => {
  let contactIds = state.contacts;
  let filteredContactsIds = state.filteredContacts;
  if (state.filter == null && isUndo) {
    contactIds = state.contacts.map(restoreDeletedContactsMapper(actionName));
    if (isPeopleOnTableView) {
      filteredContactsIds = state.filteredContacts.map(
        restoreDeletedContactsMapper(actionName)
      );
    }
  } else if (state.filter && isUndo) {
    filteredContactsIds = state.filteredContacts.map(
      restoreDeletedContactsMapper(actionName)
    );
  } else if (state.filter == null) {
    contactIds = state.contacts.map(
      softDeleteContactsMapper(actionContactIds, actionName)
    );
    if (isPeopleOnTableView) {
      filteredContactsIds = state.filteredContacts.map(
        softDeleteContactsMapper(actionContactIds, actionName)
      );
    }
  } else {
    filteredContactsIds = state.filteredContacts.map(
      softDeleteContactsMapper(actionContactIds, actionName)
    );
  }

  return {
    contactIds,
    filteredContactsIds,
  };
};

// delete from contact view if on filter
const handleShowFilterItems = (
  contacts,
  payloadContactIds,
  objectifyId,
  currentFilter,
  filterName
) => {
  let newContacts = contacts;

  let mapFunction;
  if (objectifyId) {
    mapFunction = softDeleteContactsMapper(payloadContactIds, filterName);
  } else {
    mapFunction = restoreDeletedContactsMapper(filterName);
  }

  if (newContacts && currentFilter === filterName) {
    newContacts = newContacts.map(mapFunction);
  }

  return newContacts;
};

const initialState = {
  // Contacts data
  data: {},
  dataStatus: {},

  // List
  contacts: [],
  contact: [],
  contactsLoadingMoreStatus: null,
  contactsStatus: null,
  contactsPage: null,
  contactsLoadedAll: null,
  contactsTotal: null,

  // Filtered list
  filteredContacts: [],
  filteredContactsLoadingMoreStatus: null,
  filteredContactsStatus: null,
  filteredContactsPage: null,
  filteredContactsLoadedAll: null,
  filteredContactsTotal: null,

  // Add quick
  addQuickStatus: null,
  addQuickError: null,
  addQuickErrorStatus: null,
  addQuickId: null,

  // Edit contact
  editFieldQueue: {},

  // Bathch edit contacts
  batchEditFieldQueue: {},

  // Delete contact
  deleteStatus: null,
  deleteId: null,

  // Batch delete contacts
  batchDeleteStatus: null,
  batchDeleteIds: null,

  // Check phone number
  checkPhoneNumberStatus: null,
  checkPhoneNumberDuplicates: null,
  checkPhoneNumberCountryId: null,
  checkPhoneNumberPhoneNumber: null,

  // Add contact
  addId: null,
  addStatus: null,
  addErrors: null,

  // Contact groups
  groups: null,
  groupsStatus: null,
  groupsLoadingMoreStatus: null,
  groupsPage: null,
  groupsLoadedAll: null,
  groupsContactId: null,

  // Edit contact groups
  patchGroupsStatus: null,

  // Contact tags
  tags: null,
  tagsStatus: null,
  tagsLoadingMoreStatus: null,
  tagsPage: null,
  tagsLoadedAll: null,
  tagsContactId: null,

  // Edit contact tags
  patchTagsStatus: null,

  // Contact Automated Messages Campaigns
  amCampaigns: null,
  amCampaignsStatus: null,
  amCampaignsPage: null,
  amCampaignsLoadedAll: null,
  amCampaignsContactId: null,

  // Edit contact Automated Messages Campaigns
  patchAmCampaignsStatus: null,

  // Contact campaign in People tab
  campaigns: null,
  campaignsStatus: null,
  campaignsLoadingMoreStatus: null,
  campaignsPage: null,
  campaignsQuery: null,
  campaignsLoadedAll: null,
  campaignsContactId: null,

  //Edit contact campain
  patchCampaignsStatus: null,

  // Number lookup price
  numberLookupPriceStatus: null,
  numberLookupPrice: null,
  numberLookupNumbersCount: null,
  numberLookupRequestId: null,

  // Number lookup price
  numberLookupStatus: null,
  numberLookupResult: null,

  // Queue number lookup price
  queueNumberLookupStatus: null,
  rhsItemsSelected: false,
  rhsSelectedContacts: {},
  rhsSelectionClear: false,
  filter: null,

  archiveMembersStatus: "",
  archiveNeedRouting: false,
  archivingIds: [],
  archivedSuccessContacts: [],
  primaryAssignees: [],
  archivedContactsPathname: null,

  unarchiveMembersStatus: "",
  unarchivingIds: [],
  unarchivedSuccessContacts: [],
  unarchivedContactsPathname: null,

  contactDeletingId: [],

  // unsubscribe
  unsubscribeContactsStatus: "",
  unsubscribeSnackbarData: {},

  // subscribe
  subscribeContactsStatus: "",
  subscribeSnackbarData: {},

  //export
  exportContactsStatus: {},
  exportContactsCSV: {},
  exportContactsSnackbarData: {},

  // do not call
  doNotCallContactsStatus: "",
  doNotCallContactsSnackbarData: {},

  // reset do not call
  resetDoNotCallContactsStatus: "",
  allowCallsContactsSnackbarData: {},

  // block
  blockContactsStatus: "",
  blockContactsSnackbarData: {},

  // unblock
  unblockContactsStatus: "",
  unblockContactsSnackbarData: {},

  // mark as favorite
  markAsFavoriteStatus: "",
  markAsFavoriteContactsSnackbarData: {},
  markAsUnfavoriteStatus: "",
  markAsUnFavoriteContactsSnackbarData: {},

  // contact view edit groups/tags/campaigns
  editGroupsTagsCampaignsSnackbarData: {},

  // add tag
  addTagSnackbarData: {},

  // contact ids for number look up
  contactIdsNumberLookUp: null,

  /* filter count */
  countStatus: null,
  counts: {
    all: null,
    favorites: null,
    archived: null,
    archivedBlocked: null,
    archivedNoIntegration: null,
    archivedCCB: null,
    archivedPCO: null,
    archivedRock: null,
    archivedElvanto: null,
    archivedMailchimp: null,
    archivedBreeze: null,
  },
  archivalSnackbarData: {},
  unarchivalSnackbarData: {},
  //detail logs
  details: null,
  detailsStatus: null,
  detailsLoadingMoreStatus: null,
  detailsPage: null,
  detailsLoadedAll: null,
  detailsContactId: null,

  isDeleteContactModal: null,
  contactToDelete: {},

  deleteContactIds: [],
  isBatchDelete: null,

  // Edit campaigns
  relatedCampaigns: {},

  showDetail: null,

  // create group tag
  addTagStatus: null,
  addedTag: null,

  markAsReadUnresolvedConflictsStatus: "",

  // initial contacts fetch
  initialFetch: false,
};

export default function reducer(state = initialState, action) {
  let data = null;
  let contactChanged = null;
  let dataStatus = null;
  let contacts = null;
  let editFieldQueue = null;
  let batchEditFieldQueue = null;
  let groups = null;
  let tags = null;
  let amCampaigns = null;
  let campaigns = null;
  let details = null;
  switch (action.type) {
    case "CONTACTS/FETCH.INITIAL_FETCH_FLAG":
      return {
        ...state,
        initialFetch: action.fetched,
      };

    // List
    case "CONTACTS/FETCH":
      return {
        ...state,
        contactsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        contactsStatus: "loading",
      };
    case "CONTACTS/FETCH.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };

      contacts = action.payload.contacts_ids;
      if (state.contacts && state.contactsPage + 1 === action.page) {
        let prevContacts = [];
        for (let i = 0; i < state.contacts.length; i++) {
          if (contacts.indexOf(state.contacts[i]) === -1) {
            prevContacts.push(state.contacts[i]);
          }
        }
        contacts = prevContacts.concat(contacts);
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        contacts: contacts,
        contactsLoadingMoreStatus: action.page > 1 ? "success" : null,
        contactsStatus: "success",
        contactsPage: action.page,
        contactsLoadedAll: action.payload.contacts_ids.length === 0,
        contactsTotal: action.payload.total,
      };
    case "CONTACTS/FETCH.ERROR":
      return {
        ...state,
        contacts: null,
        contactsLoadingMoreStatus: action.page > 1 ? "error" : null,
        contactsStatus: "error",
        contactsPage: null,
        contactsLoadedAll: null,
        contactsTotal: null,
      };

    case "CONTACTS/FETCH_TEMP":
      dataStatus = { ...state.dataStatus };
      dataStatus[action.contactId] = "loading";
      return {
        ...state,
        dataStatus: dataStatus,
      };

    case "CONTACTS/FETCH_TEMP.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";

      contacts = [...state.contacts];
      if (contacts.indexOf(action.contactId) === -1) {
        contacts.unshift(action.contactId);
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        contacts: contacts,
      };

    case "CONTACTS/FETCH_TEMP.ERROR":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = null;
      dataStatus[action.contactId] = "error";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
      };

    // Filtered list
    case "CONTACTS/FETCH_FILTERED":
      return {
        ...state,
        filteredContactsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        filteredContactsStatus: "loading",
      };
    case "CONTACTS/FETCH_FILTERED.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      if (action.page === 1 || !state.filteredContacts) {
        contacts = action.payload.contacts_ids;
      } else {
        contacts = state.filteredContacts.concat(action.payload.contacts_ids);
      }
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        filteredContacts: contacts,
        filteredContactsLoadingMoreStatus: action.page > 1 ? "success" : null,
        filteredContactsStatus: "success",
        filteredContactsPage: action.page,
        filteredContactsLoadedAll: action.payload.contacts_ids.length === 0,
        filteredContactsTotal: action.payload.total,
      };
    case "CONTACTS/FETCH_FILTERED.ERROR":
      return {
        ...state,
        filteredContacts: null,
        filteredContactsLoadingMoreStatus: action.page > 1 ? "error" : null,
        filteredContactsStatus: "error",
        filteredContactsPage: null,
        filteredContactsLoadedAll: null,
        filteredContactsTotal: null,
      };

    // Fetch contact data
    case "CONTACTS/FETCH_DATA":
      dataStatus = { ...state.dataStatus };
      dataStatus[action.contactId] = "loading";
      return {
        ...state,
        dataStatus: dataStatus,
      };
    case "CONTACTS/FETCH_DATA.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      data[action.contactId]["__switchedCompanyId"] =
        action.payload.switchedCompanyId;
      dataStatus[action.contactId] = "success";

      // Add the contact first on the list if is not already in the list
      if (action.addContactAsFirst) {
        contacts = [...state.contacts];
        if (contacts.indexOf(action.contactId) === -1) {
          contacts.unshift(action.contactId);
        }
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        contact: data,
        contacts: contacts ? contacts : state.contacts,
      };
    case "CONTACTS/FETCH_DATA.ERROR":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = null;
      dataStatus[action.contactId] = "error";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
      };

    // Add quick
    case "CONTACTS/ADD_QUICK":
      return {
        ...state,
        addQuickStatus: "loading",
        addQuickError: null,
        addQuickErrorStatus: null,
      };
    case "CONTACTS/ADD_QUICK.SUCCESS":
      return {
        ...state,
        addQuickStatus: "success",
        addQuickError: null,
        addQuickErrorStatus: null,
        addQuickId: action.payload.id,
        // reset list
        contacts: null,
        contactsLoadingMoreStatus: null,
        contactsStatus: null,
        contactsPage: null,
        contactsLoadedAll: null,
      };
    case "CONTACTS/ADD_QUICK.ERROR":
      return {
        ...state,
        addQuickStatus: "error",
        addQuickError: null,
        addQuickErrorStatus: null,
        addQuickId: null,
      };

    // Edit contact field
    case "CONTACTS/EDIT_FIELD": {
      editFieldQueue = { ...state.editFieldQueue };
      editFieldQueue[action.requestId] = {
        contactId: action.contactId,
        contactData: action.contactData,
        status: "loading",
      };
      return {
        ...state,
        editFieldQueue,
      };
    }

    case "CONTACTS/EDIT_FIELD.SUCCESS": {
      data = { ...state.data };
      const contact = { ...state.contact };
      dataStatus = { ...state.dataStatus };
      data[action.payload.contact.id] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.payload.contact.id] = "success";
      contact[action.payload.contact.id] = action.payload.contact;

      editFieldQueue = { ...state.editFieldQueue };
      if (typeof editFieldQueue[action.requestId] !== "undefined") {
        editFieldQueue[action.requestId].status = "success";
      }
      return {
        ...state,
        data,
        dataStatus,
        editFieldQueue,
        contact,
      };
    }

    case "CONTACTS/EDIT_FIELD.ERROR": {
      editFieldQueue = { ...state.editFieldQueue };
      if (typeof editFieldQueue[action.requestId] !== "undefined") {
        editFieldQueue[action.requestId].status = "error";
      }
      return {
        ...state,
        editFieldQueue,
      };
    }

    case "CONTACTS/EDIT_FIELD.RESET_STATUS": {
      editFieldQueue = { ...state.editFieldQueue };
      editFieldQueue[action.requestId] = undefined;

      return {
        ...state,
        editFieldQueue,
      };
    }

    case "CONTACTS/CLEAR_EDIT_FIELD_QUEUE": {
      return {
        ...state,
        editFieldQueue: {},
      };
    }

    // Batch edit contacts field
    case "CONTACTS/BATCH_EDIT_FIELD":
      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      batchEditFieldQueue[action.requestId] = {
        contactIds: action.contactIds,
        contactData: action.contactData,
        status: "loading",
      };
      return {
        ...state,
        batchEditFieldQueue,
      };
    case "CONTACTS/BATCH_EDIT_FIELD.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };

      let editedIds = [];
      for (let i = 0; i < action.payload.contacts.length; i++) {
        editedIds.push(action.payload.contacts[i].id);
        data[action.payload.contacts[i].id] = action.payload.contacts[i];
        data[action.payload.contacts[i].id]["__full"] = true;
        dataStatus[action.payload.contacts[i].id] = "success";
      }

      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      if (typeof batchEditFieldQueue[action.requestId] !== "undefined") {
        batchEditFieldQueue[action.requestId].status = "success";
      }

      let newFilteredContacts = state.filteredContacts;

      if (
        action.contactData.is_favorite === 0 ||
        action.contactData.is_number_favorite === 0
      ) {
        newFilteredContacts = handleShowFilterItems(
          state.filteredContacts,
          action.payload.contactIds,
          true,
          state.filter,
          "favorites"
        );
      }

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data,
        dataStatus,
        batchEditFieldQueue,
      };
    case "CONTACTS/BATCH_EDIT_FIELD.ERROR":
      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      if (typeof batchEditFieldQueue[action.requestId] !== "undefined") {
        batchEditFieldQueue[action.requestId].status = "error";
      }
      return {
        ...state,
        batchEditFieldQueue,
      };

    case "CONTACTS/BATCH_EDIT_FIELD.CLEAR": {
      batchEditFieldQueue = { ...state.batchEditFieldQueue };
      batchEditFieldQueue[action.requestId].status = "";
      return {
        ...state,
        batchEditFieldQueue,
      };
    }

    // Delete contact
    case "CONTACTS/DELETE": {
      const contactDeletingId = state.contactDeletingId
        ? [...state.contactDeletingId]
        : [];
      contactDeletingId.push(action.contactId);
      return {
        ...state,
        deleteStatus: "loading",
        deleteId: action.contactId,
        contactDeletingId,
      };
    }
    case "CONTACTS/DELETE.SUCCESS": {
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };

      if (typeof data[action.contactId] !== "undefined") {
        delete data[action.contactId];
      }
      dataStatus[action.contactId] = "deleted";
      let contactDeletingId = state.contactDeletingId
        ? [...state.contactDeletingId]
        : [];
      const rmContactDeleteIds = action.contactId ? [action.contactId] : [];
      contactDeletingId = rmContactDeleteIds.filter(
        (el) => !rmContactDeleteIds.includes(el)
      );

      return {
        ...state,
        data,
        dataStatus,
        deleteStatus: `success-${action.contactId}`,
        deleteId: action.contactId,
        contactDeletingId,
      };
    }
    case "CONTACTS/DELETE.ERROR":
      let contactDeletingIdNew = state.contactDeletingId
        ? [...state.contactDeletingId]
        : [];
      const rmContactDeleteIds = action.contactId ? [action.contactId] : [];
      contactDeletingIdNew = rmContactDeleteIds.filter(
        (el) => !rmContactDeleteIds.includes(el)
      );
      return {
        ...state,
        deleteStatus: "error",
        deleteId: action.contactId,
        contactDeletingId: contactDeletingIdNew,
      };

    case "CONTACTS/DELETE.CLEAR": {
      return {
        ...state,
        deleteStatus: null,
        deleteId: null,
        contactDeletingId: [],
      };
    }

    // Batch delete contacts
    case "CONTACTS/BATCH_DELETE":
      return {
        ...state,
        batchDeleteStatus: "loading",
        batchDeleteIds: action.contactIds,
      };
    case "CONTACTS/BATCH_DELETE.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      for (let i = 0; i < action.payload.deletedContactIds.length; i++) {
        const id = action.payload.deletedContactIds[i];
        if (typeof data[id] !== "undefined") {
          delete data[id];
        }
        dataStatus[id] = "deleted";
      }

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,

        // Reset contacts list
        contacts: null,
        contactsLoadingMoreStatus: null,
        contactsStatus: null,
        contactsPage: null,
        contactsLoadedAll: null,

        batchDeleteStatus: "success",
        batchDeleteIds: action.payload.deletedContactIds,
      };
    case "CONTACTS/BATCH_DELETE.ERROR":
      return {
        ...state,
        batchDeleteStatus: "error",
        batchDeleteIds: action.contactIds,
      };

    // Check phone number
    case "CONTACTS/CHECK_PHONE_NUMBER":
      return {
        ...state,
        checkPhoneNumberStatus: "loading",
      };
    case "CONTACTS/CHECK_PHONE_NUMBER.SUCCESS":
      return {
        ...state,
        checkPhoneNumberStatus: "success",
        checkPhoneNumberDuplicates: action.payload.duplicates,
        checkPhoneNumberCountryId: action.countryId,
        checkPhoneNumberPhoneNumber: action.phoneNumber,
        checkNotAllowedContacts: action.payload.not_allowed_contacts,
      };
    case "CONTACTS/CHECK_PHONE_NUMBER.ERROR":
      return {
        ...state,
        checkPhoneNumberStatus: "error",
        checkPhoneNumberDuplicates: null,
        checkPhoneNumberCountryId: action.countryId,
        checkPhoneNumberPhoneNumber: action.phoneNumber,
      };
    case "CONTACTS/CLEAR_CHECK_PHONE_NUMBER_STATUS":
      return {
        ...state,
        checkPhoneNumberStatus: "",
        checkPhoneNumberDuplicates: null,
        checkPhoneNumberCountryId: null,
        checkPhoneNumberPhoneNumber: null,
        checkNotAllowedContacts: null,
      };
    // Add contacts
    case "CONTACTS/ADD":
      return {
        ...state,
        addId: null,
        addStatus: "loading",
        addErrors: null,
      };
    case "CONTACTS/ADD.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.payload.contact.id] = action.payload.contact;
      //data[action.payload.contact.id]['__full'] = true;
      dataStatus[action.payload.contact.id] = "success";

      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        addId: action.payload.contact.id,
        addStatus: "success",
        addErrors: null,
        // reset list
        contacts: null,
        contactsLoadingMoreStatus: null,
        contactsStatus: null,
        contactsPage: null,
        contactsLoadedAll: null,
      };
    case "CONTACTS/ADD.ERROR":
      return {
        ...state,
        addId: null,
        addStatus: "error",
        addErrors:
          action.payload && typeof action.payload.errors !== "undefined"
            ? action.payload.errors
            : null,
      };
    case "CONTACTS/ADD.CLEAR":
      return {
        ...state,
        addId: null,
        addStatus: null,
        addErrors: null,
      };

    // Contact groups
    case "CONTACTS/GROUPS":
      return {
        ...state,
        groupsStatus: "loading",
        groupsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        groupsContactId: action.contactId,
      };
    case "CONTACTS/GROUPS.SUCCESS":
      if (action.page === null || action.page === 1 || !state.groups) {
        groups = action.payload.groups;
      } else {
        groups = state.groups.concat(action.payload.groups);
      }
      return {
        ...state,
        groups: groups,
        groupsStatus: "success",
        groupsLoadingMoreStatus: action.page > 1 ? "success" : null,
        groupsPage: action.page,
        groupsLoadedAll: action.payload.groups.length === 0,
        groupsContactId: action.contactId,
      };
    case "CONTACTS/GROUPS.ERROR":
      return {
        ...state,
        groups: null,
        groupsStatus: "error",
        groupsLoadingMoreStatus: action.page > 1 ? "error" : null,
        groupsPage: null,
        groupsLoadedAll: null,
        groupsContactId: action.contactId,
      };
    case "CONTACTS/GROUPS.CLEAR":
      return {
        ...state,
        groups: null,
        groupsStatus: null,
        groupsPage: null,
        groupsLoadedAll: null,
        groupsContactId: null,
      };

    // Edit contact groups
    case "CONTACTS/PATCH_GROUPS":
      return {
        ...state,
        patchGroupsStatus: "loading",
      };
    case "CONTACTS/PATCH_GROUPS.SUCCESS":
      data = { ...state.data };
      contactChanged = { ...state.contact };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      contactChanged[action.contactId] = action.payload.contact;
      contactChanged[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";
      return {
        ...state,
        contact: contactChanged,
        data: data,
        dataStatus: dataStatus,
        patchGroupsStatus: "success",
      };
    case "CONTACTS/PATCH_GROUPS.ERROR":
      return {
        ...state,
        patchGroupsStatus: "error",
      };
    case "CONTACTS/PATCH_GROUPS.CLEAR":
      return {
        ...state,
        patchGroupsStatus: null,
      };

    // Contact tags
    case "CONTACTS/TAGS":
      return {
        ...state,
        tagsStatus: "loading",
        tagsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        tagsContactId: action.contactId,
      };
    case "CONTACTS/TAGS.SUCCESS":
      if (action.page === 1 || !state.tags) {
        tags = action.payload.tags;
      } else {
        tags = state.tags.concat(action.payload.tags);
      }
      return {
        ...state,
        tags: tags,
        tagsStatus: "success",
        tagsLoadingMoreStatus: action.page > 1 ? "success" : null,
        tagsPage: action.page,
        tagsLoadedAll: action.payload.tags.length === 0,
      };
    case "CONTACTS/TAGS.ERROR":
      return {
        ...state,
        tags: null,
        tagsStatus: "error",
        tagsLoadingMoreStatus: action.page > 1 ? "error" : null,
        tagsPage: null,
        tagsLoadedAll: null,
        tagsContactId: action.contactId,
      };
    case "CONTACTS/TAGS.CLEAR":
      return {
        ...state,
        tags: null,
        tagsStatus: null,
        tagsLoadingMoreStatus: null,
        tagsPage: null,
        tagsLoadedAll: null,
        tagsContactId: null,
      };

    // Edit contact tags
    case "CONTACTS/PATCH_TAGS":
      return {
        ...state,
        patchTagsStatus: "loading",
      };
    case "CONTACTS/PATCH_TAGS.SUCCESS":
      data = { ...state.data };
      contactChanged = { ...state.contact };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      contactChanged[action.contactId] = action.payload.contact;
      contactChanged[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";
      return {
        ...state,
        contact: contactChanged,
        data: data,
        dataStatus: dataStatus,
        patchTagsStatus: "success",
      };
    case "CONTACTS/PATCH_TAGS.ERROR":
      return {
        ...state,
        patchTagsStatus: "error",
      };
    case "CONTACTS/PATCH_TAGS.CLEAR":
      return {
        ...state,
        patchTagsStatus: null,
      };

    // Contact Automated Messages Campaigns
    case "CONTACTS/AM_CAMPAIGNS":
      return {
        ...state,
        amCampaignsStatus: "loading",
        amCampaignsContactId: action.contactId,
      };
    case "CONTACTS/AM_CAMPAIGNS.SUCCESS":
      if (action.page === 1 || !state.amCampaigns) {
        amCampaigns = action.payload.campaigns;
      } else {
        amCampaigns = state.amCampaigns.concat(action.payload.campaigns);
      }
      return {
        ...state,
        amCampaigns: amCampaigns,
        amCampaignsStatus: "success",
        amCampaignsPage: action.page,
        amCampaignsLoadedAll: action.payload.campaigns.length === 0,
        amCampaignsContactId: action.contactId,
      };
    case "CONTACTS/AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: "error",
        amCampaignsPage: null,
        amCampaignsLoadedAll: null,
        amCampaignsContactId: action.contactId,
      };
    case "CONTACTS/AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        amCampaigns: null,
        amCampaignsStatus: null,
        amCampaignsPage: null,
        amCampaignsLoadedAll: null,
        amCampaignsContactId: null,
      };

    // Edit contact Automated Messages Campaigns
    case "CONTACTS/PATCH_AM_CAMPAIGNS":
      return {
        ...state,
        patchAmCampaignsStatus: "loading",
      };
    case "CONTACTS/PATCH_AM_CAMPAIGNS.SUCCESS":
      data = { ...state.data };
      dataStatus = { ...state.dataStatus };
      data[action.contactId] = action.payload.contact;
      data[action.contactId]["__full"] = true;
      dataStatus[action.contactId] = "success";
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        patchAmCampaignsStatus: "success",
      };
    case "CONTACTS/PATCH_AM_CAMPAIGNS.ERROR":
      return {
        ...state,
        patchAmCampaignsStatus: "error",
      };
    case "CONTACTS/PATCH_AM_CAMPAIGNS.CLEAR":
      return {
        ...state,
        patchAmCampaignsStatus: null,
      };
    // Contact campaign people
    case "CONTACTS/CAMPAIGNS":
      return {
        ...state,
        campaignsStatus: "loading",
        campaignsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        campaignsContactId: action.contactId,
      };
    case "CONTACTS/CAMPAIGNS.SUCCESS":
      if (action.page === 1 || !state.campaigns) {
        campaigns = action.payload.campaigns;
      } else {
        campaigns = state.campaigns.concat(action.payload.campaigns);
      }
      return {
        ...state,
        campaigns: campaigns,
        campaignsStatus: "success",
        campaignsLoadingMoreStatus: action.page > 1 ? "success" : null,
        campaignsPage: action.page,
        campaignsQuery: action.query,
        campaignsLoadedAll: action.payload.campaigns.length === 0,
        campaignsContactId: action.contactId,
      };
    case "CONTACTS/CAMPAIGNS.ERROR":
      return {
        ...state,
        campaigns: null,
        campaignsStatus: "error",
        campaignsLoadingMoreStatus: action.page > 1 ? "error" : null,
        campaignsPage: null,
        campaignsQuery: "",
        campaignsLoadedAll: null,
        campaignsContactId: action.contactId,
      };
    case "CONTACTS/CAMPAIGNS.CLEAR":
      return {
        ...state,
        campaigns: null,
        campaignsStatus: null,
        campaignsPage: null,
        campaignsQuery: "",
        campaignsLoadedAll: null,
        campaignsContactId: null,
      };

    // Edit contact Campaigns people
    case "CONTACTS/PATCH_CAMPAIGNS":
      return {
        ...state,
        patchCampaignsStatus: "loading",
      };

    case "CONTACTS/PATCH_CAMPAIGNS.SUCCESS": {
      data = { ...state.data };
      data[action.contactId] = action.payload.contact;
      return {
        ...state,
        patchCampaignsStatus: "success",
        relatedCampaigns: action.payload.relatedCampaigns,
        data,
      };
    }

    case "CONTACTS/PATCH_CAMPAIGNS.ERROR":
      return {
        ...state,
        patchCampaignsStatus: "error",
      };
    case "CONTACTS/PATCH_CAMPAIGNS.CLEAR":
      return {
        ...state,
        patchCampaignsStatus: null,
      };

    //Detail logs
    case "CONTACTS/DETAILS":
      return {
        ...state,
        detailsStatus: "loading",
        detailsLoadingMoreStatus: action.page > 1 ? "loading" : null,
        detailsContactId: action.contactId,
        details: action.contactData,
      };
    case "CONTACTS/DETAILS.SUCCESS":
      if (action.page === 1 || !state.details) {
        details = action.payload.changes_log;
      } else {
        details = state.details.concat(action.payload);
      }
      return {
        ...state,
        details: details,
        detailsStatus: "success",
        detailsLoadingMoreStatus: action.page > 1 ? "success" : null,
        detailsPage: action.page,
        detailsQuery: action.query,
        detailsLoadedAll: action.payload.changes_log.length === 0,
      };
    case "CONTACTS/DETAILS.ERROR":
      return {
        ...state,
        details: null,
        detailsStatus: "error",
        detailsLoadingMoreStatus: action.page > 1 ? "error" : null,
        detailsPage: null,
        detailsLoadedAll: null,
        detailsContactId: action.contactId,
      };
    case "CONTACTS/DETAILS.CLEAR":
      return {
        ...state,
        details: null,
        detailsStatus: null,
        detailsLoadingMoreStatus: null,
        detailsPage: null,
        detailsLoadedAll: null,
        detailsContactId: null,
      };

    // Number lookup price
    case "CONTACTS/LOOKUP_PRICE":
      return {
        ...state,
        numberLookupPriceStatus: "loading",
        numberLookupPrice: null,
        numberLookupNumbersCount: null,
        numberLookupRequestId: action?.requestId || null,
      };
    case "CONTACTS/LOOKUP_PRICE.SUCCESS":
      return {
        ...state,
        numberLookupPriceStatus: "success",
        numberLookupPrice: action.payload.price,
        numberLookupNumbersCount: action.payload.numbers_count,
        numberLookupRequestId: action?.payload?.request_id || null,
      };
    case "CONTACTS/LOOKUP_PRICE.ERROR":
      return {
        ...state,
        numberLookupPriceStatus: "error",
        numberLookupPrice: null,
        numberLookupNumbersCount: null,
        numberLookupRequestId: null,
      };
    case "CONTACTS/LOOKUP_PRICE.CLEAR":
      return {
        ...state,
        numberLookupPriceStatus: null,
        numberLookupPrice: null,
        numberLookupNumbersCount: null,
      };

    // Number lookup
    case "CONTACTS/LOOKUP":
      return {
        ...state,
        numberLookupStatus: "loading",
        numberLookupResult: null,
      };
    case "CONTACTS/LOOKUP.SUCCESS":
      data = { ...state.data, ...action.payload.contacts };
      dataStatus = {
        ...state.dataStatus,
        ...fromEntries(
          new Map(
            Object.keys(action.payload.contacts).map((item, index) => {
              return [index, "success"];
            })
          )
        ),
      };
      return {
        ...state,
        data: data,
        dataStatus: dataStatus,
        numberLookupStatus: "success",
        numberLookupResult: action.payload.result,
      };
    case "CONTACTS/LOOKUP.ERROR":
      return {
        ...state,
        numberLookupStatus: "error",
        numberLookupResult: null,
      };
    case "CONTACTS/LOOKUP.CLEAR":
      return {
        ...state,
        numberLookupStatus: null,
        numberLookupResult: null,
      };

    // Queue number lookup
    case "CONTACTS/QUEUE_LOOKUP":
      return {
        ...state,
        queueNumberLookupStatus: "loading",
      };
    case "CONTACTS/QUEUE_LOOKUP.SUCCESS":
      return {
        ...state,
        queueNumberLookupStatus: "success",
      };
    case "CONTACTS/QUEUE_LOOKUP.ERROR": {
      return {
        ...state,
        queueNumberLookupStatus: action?.status === 504 ? "success" : "error",
      };
    }

    case "CONTACTS/QUEUE_LOOKUP.CLEAR":
      return {
        ...state,
        queueNumberLookupStatus: null,
      };

    case "CONTACTS/FILTER":
      return {
        ...state,
        filter: action.filter === "all" ? null : action.filter,
      };

    case "CONTACTS/GROUPS.RHS_SELECT":
      return {
        ...state,
        rhsItemsSelected: action.selection,
        rhsSelectedContacts: action.selectedContacts,
      };

    case "CONTACTS/GROUPS.RHS_SELECTION_CLEARED":
      return {
        ...state,
        rhsSelectionClear: action.selection,
        rhsItemsSelected: 0,
        rhsSelectedContacts: {},
      };

    // Archive Contacts

    case "CONTACTS/ARCHIVE_CONTACTS": {
      const archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      archivingIds.push(...action.archivingIds);
      return {
        ...state,
        archiveMembersStatus: "loading",
        archivingIds,
        archivedSuccessContacts: [],
        archiveNeedRouting: false,
      };
    }

    case "CONTACTS/ARCHIVE_CONTACTS.SUCCESS": {
      let archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      const rmArchingIds = action.archivingIds ? action.archivingIds : [];
      archivingIds = archivingIds.filter((el) => !rmArchingIds.includes(el));
      // update HubContactsListView by archived contacts
      const { contactIds, filteredContactsIds } = deleteRestoreBasedOnAction(
        state,
        action.contactIds,
        action.isUndo,
        "archive",
        action.isPeopleOnTableView
      );

      const failedToArchiveNumber =
        parseInt(action.payload.skipped_no_new_primary_per_number?.length) +
        parseInt(action.payload.skipped_duplicates_per_number?.length);

      return {
        ...state,
        data: { ...state.data, ...action.payload.contacts },
        contacts: contactIds,
        filteredContacts: filteredContactsIds,
        archiveMembersStatus: `success-${action.workflow_id}`,
        archivedSuccessContacts: [...action.contactIds],
        primaryAssignees: [...action.primaryAssignees],
        archiveNeedRouting: action.needRouting,
        archivingIds,
        failedToArchiveNumber,
      };
    }

    case "CONTACTS/ARCHIVE_CONTACTS.FAILURE": {
      let archivingIds = state.archivingIds ? [...state.archivingIds] : [];
      const rmArchingIds = action.archivingIds ? action.archivingIds : [];
      archivingIds = archivingIds.filter((el) => !rmArchingIds.includes(el));

      return {
        ...state,
        archiveMembersStatus: `failure-${action.workflow_id}`,
        archivedSuccessContacts: [],
        archivingIds,
        archivedContactsPathname:
          action.payload?.response?.status === 504
            ? window.location?.pathname
            : null,
      };
    }

    case "CONTACTS/ARCHIVE_CONTACTS_PATHNAME.CLEAR": {
      return {
        ...state,
        archivedContactsPathname: null,
      };
    }

    // UnArchive Contacts

    case "CONTACTS/UNARCHIVE_CONTACTS": {
      const unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      unarchivingIds.push(...action.unarchivingIds);
      return {
        ...state,
        unarchiveMembersStatus: "loading",
        unarchivingIds,
        unarchivedSuccessContacts: [],
        archiveNeedRouting: false,
      };
    }

    case "CONTACTS/UNARCHIVE_CONTACTS.SUCCESS": {
      let unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      const rmUnarchingIds = action.unarchivingIds ? action.unarchivingIds : [];
      unarchivingIds = unarchivingIds.filter(
        (el) => !rmUnarchingIds.includes(el)
      );

      const failedToUnarchiveNumber =
        parseInt(action.payload.skipped_no_new_primary_per_number?.length) +
        parseInt(action.payload.skipped_duplicates_per_number?.length);

      const { contactIds, filteredContactsIds } = deleteRestoreBasedOnAction(
        state,
        action.contactIds,
        action.isUndo,
        "archive",
        action.isPeopleOnTableView
      );

      // update HubContactsListView by un archived contacts

      return {
        ...state,
        data: { ...state.data, ...action.payload.contacts },
        filteredContacts: filteredContactsIds,
        contacts: contactIds,
        unarchiveMembersStatus: `success-${action.workflow_id}`,
        unarchivedSuccessContacts: [...action.contactIds],
        primaryAssignees: [...action.primaryAssignees],
        archiveNeedRouting: action.needRouting,
        unarchivingIds,
        failedToUnarchiveNumber,
      };
    }

    case "CONTACTS/UNARCHIVE_CONTACTS.FAILURE": {
      let unarchivingIds = state.unarchivingIds
        ? [...state.unarchivingIds]
        : [];
      const rmUnarchingIds = action.unarchivingIds ? action.unarchivingIds : [];
      unarchivingIds = unarchivingIds.filter(
        (el) => !rmUnarchingIds.includes(el)
      );
      return {
        ...state,
        unarchiveMembersStatus: `failure-${action.workflow_id}`,
        unarchivedSuccessContacts: [],
        unarchivingIds,
        unarchivedContactsPathname:
          action.payload?.response?.status === 504
            ? window.location?.pathname
            : null,
      };
    }

    case "CONTACTS/UNARCHIVE_CONTACTS_PATHNAME.CLEAR": {
      return {
        ...state,
        unarchivedContactsPathname: null,
      };
    }

    case "CONTACTS/CLEAR_ARCHIVE_DATA":
      return {
        ...state,
        archiveMembersStatus: "",
        archivedSuccessContacts: [],
        unarchiveMembersStatus: "",
        unarchivedSuccessContacts: [],
        unarchivingIds: [],
        archivingIds: [],
        failedToArchiveNumber: 0,
        failedToUnarchiveNumber: 0,
      };

    case "CONTACTS/COUNTS":
      return {
        ...state,
        countStatus: "loading",
      };
    case "CONTACTS/COUNTS.SUCCESS": {
      const counts = {
        ...state,
        all: action.payload.all ?? state.counts.all,
        favorites: action.payload.favorites ?? state.counts.favorites,
        contactStatus:
          parseInt(action.payload["no-number"]) +
            parseInt(action.payload["no-address"]) +
            parseInt(action.payload["no-birthday"]) +
            parseInt(action.payload["no-email"]) +
            parseInt(action.payload["no-name"]) +
            parseInt(action.payload["with-address"]) +
            parseInt(action.payload["with-birthday"]) +
            parseInt(action.payload["with-email"]) +
            parseInt(action.payload["with-name"]) +
            parseInt(action.payload["with-number"]) ??
          state.counts?.contactStatus,
        nsNoNumber: action.payload["no-number"] ?? state.counts?.nsNoNumber,
        nsFormattingError:
          action.payload["unformatted-number"] ?? state.counts?.nsNoNumber,
        phoneType:
          parseInt(action.payload.mobile) +
            parseInt(action.payload.landline) +
            parseInt(action.payload.voip) +
            parseInt(action.payload["number-not-checked"]) +
            parseInt(action.payload["unformatted-number"]) ??
          state.counts?.phoneType,
        ptMobile: action.payload.mobile ?? state.counts?.ptMobile,
        ptLandline: action.payload.landline ?? state.counts?.ptLandline,
        ptVOIP: action.payload.voip ?? state.counts?.ptVOIP,
        ptUnchecked:
          action.payload["number-not-checked"] ?? state.counts?.ptUnchecked,
        integration:
          parseInt(action.payload["no-integration"]) +
            parseInt(action.payload.ccb) +
            parseInt(action.payload.pco) +
            parseInt(action.payload.elvanto) +
            parseInt(action.payload.mailchimp) +
            parseInt(action.payload.breeze) ?? state.counts?.integration,
        intNone: action.payload["no-integration"] ?? state.counts?.intNone,
        intCCB: action.payload.ccb ?? state.counts?.intCCB,
        intPCO: action.payload.pco ?? state.counts?.intPCO,
        intElvanto: action.payload.elvanto ?? state.counts?.intElvanto,
        intRockRMS: action.payload.rock ?? state.counts?.intRockRMS ?? 0,
        intMailchimp: action.payload["mailchimp"] ?? state.counts?.intMailchimp,
        intBreeze: action.payload.breeze ?? state.counts?.intBreeze,
        archived: action.payload.archived ?? state.counts?.archived,
        archivedBlocked:
          action.payload["archived-blocked"] ?? state.counts?.archivedBlocked,
        archivedNoIntegration:
          action.payload["archived-no-integration"] ??
          state.counts?.archivedNoIntegration,
        archivedCCB:
          action.payload["archived-ccb"] ?? state.counts?.archivedCCB,
        archivedPCO:
          action.payload["archived-pco"] ?? state.counts?.archivedPCO ?? 0,
        archivedRock:
          action.payload["archived-rock"] ?? state.counts?.archivedRock ?? 0,
        archivedElvanto:
          action.payload["archived-elvanto"] ?? state.counts?.archivedElvanto,
        archivedMailchimp:
          action.payload["archived-mailchimp"] ??
          state.counts?.archivedMailchimp,
        archivedBreeze:
          action.payload["archived-breeze"] ?? state.counts?.archivedBreeze,
        optIn:
          parseInt(action.payload["opt-in"]) +
            parseInt(action.payload["opted-out-likely"]) ?? state.counts?.optIn,
        optInTexts:
          parseInt(action.payload["opt-in-texts"]) +
            parseInt(action.payload["opted-out-likely"]) ??
          state.counts?.optInTexts,
        optInVoice: action.payload["opt-in-voice"] ?? state.counts?.optInVoice,
        optInMC: action.payload["mailchimp"] ?? state.counts?.optInMC,
        optInTextsUnsubscribed:
          action.payload["unsubscribed"] ??
          state.counts?.optInTextsUnsubscribed,
        optInTextsOptedOut:
          action.payload["opted-out"] ?? state.counts?.optInTextsOptedOut,
        optInTextsOptedOutLikely:
          action.payload["opted-out-likely"] ??
          state.counts?.optInTextsOptedOutLikely,
        optInVoiceUnsubscribed: "", // to do: add count when available
        optInVoiceDNC:
          action.payload["do-not-call"] ?? state.counts?.optInVoiceDNC,
        optInMCUnsubscribed:
          action.payload["mc-unsubscribed"] ??
          state.counts?.optInMCUnsubscribed,
        optInMCSubscribed:
          action.payload["mc-subscribed"] ?? state.counts?.optInMCSubscribed,
        optInMCNonsubscribed:
          action.payload["mc-non-subscribed"] ??
          state.counts?.optInMCNonsubscribed,
        optInMCCleaned:
          action.payload["mc-cleaned"] ?? state.counts?.optInMCCleaned,
        optInBlocked: action.payload["blocked"] ?? state.counts?.optInBlocked,
        noAddress: action.payload["no-address"] ?? state.counts?.noAddress,
        noBirthday: action.payload["no-birthday"] ?? state.counts?.noBirthday,
        noEmail: action.payload["no-email"] ?? state.counts?.noEmail,
        noName: action.payload["no-name"] ?? state.counts?.noName,
        withAddress:
          action.payload["with-address"] ?? state.counts?.withAddress,
        withBirthday:
          action.payload["with-birthday"] ?? state.counts?.withBirthday,
        withEmail: action.payload["with-email"] ?? state.counts?.withEmail,
        withName: action.payload["with-name"] ?? state.counts?.withName,
        withNumber: action.payload["with-number"] ?? state.counts?.withNumber,
        allAddress:
          parseInt(action.payload["no-address"]) +
            parseInt(action.payload["with-address"]) ??
          state.counts?.allAddress,
        allBirthday:
          parseInt(action.payload["no-birthday"]) +
            parseInt(action.payload["with-birthday"]) ??
          state.counts?.allBirthday,
        allEmail:
          parseInt(action.payload["no-email"]) +
            parseInt(action.payload["with-email"]) ?? state.counts?.allEmail,
        allName:
          parseInt(action.payload["no-name"]) +
            parseInt(action.payload["with-name"]) ?? state.counts?.allName,
        allNumber:
          parseInt(action.payload["no-number"]) +
            parseInt(action.payload["with-number"]) ?? state.counts?.allNumber,
        // multiAppTotal:
        //   parseInt(action.payload["multi-app"]) +
        //   parseInt(action.payload["multi-app-breeze"]) +
        //   parseInt(action.payload["multi-app-ccb"]) +
        //   parseInt(action.payload["multi-app-elvanto"]) +
        //   parseInt(action.payload["multi-app-mc"]) +
        //   parseInt(action.payload["multi-app-pco"]),
        multiAppAll:
          parseInt(action.payload["multi-app"]) ?? state.counts?.multiAppAll,
        multiAppBreeze:
          parseInt(action.payload["multi-app-breeze"]) ??
          state.counts?.multiAppBreeze,
        multiAppCCB:
          parseInt(action.payload["multi-app-ccb"]) ??
          state.counts?.multiAppCCB,
        multiAppElvanto:
          parseInt(action.payload["multi-app-elvanto"]) ??
          state.counts?.multiAppElvanto,
        multiAppRock:
          parseInt(action.payload["multi-app-rock"]) ??
          state.counts?.multiAppRock,
        multiAppMailchimp:
          parseInt(action.payload["multi-app-mc"]) ??
          state.counts?.multiAppMailchimp,
        multiAppPCO:
          parseInt(action.payload["multi-app-pco"]) ??
          state.counts?.multiAppPCO,
        holdAllUnread:
          parseInt(action.payload["hold-all-unread"]) >= 0
            ? parseInt(action.payload["hold-all-unread"])
            : state.counts?.holdAllUnread,
        holdAll:
          parseInt(action.payload["hold-all"]) >= 0
            ? parseInt(action.payload["hold-all"])
            : state.counts?.holdAll,
        holdBreeze:
          parseInt(action.payload["hold-breeze"]) >= 0
            ? parseInt(action.payload["hold-breeze"])
            : state.counts?.holdBreeze,
        holdCCB:
          parseInt(action.payload["hold-ccb"]) >= 0
            ? parseInt(action.payload["hold-ccb"])
            : state.counts?.holdCCB,
        holdElvanto:
          parseInt(action.payload["hold-elvanto"]) >= 0
            ? parseInt(action.payload["hold-elvanto"])
            : state.counts?.holdElvanto,
        holdMC:
          parseInt(action.payload["hold-mc"]) >= 0
            ? parseInt(action.payload["hold-mc"])
            : state.counts?.holdMC,
        holdPCO:
          parseInt(action.payload["hold-pco"]) >= 0
            ? parseInt(action.payload["hold-pco"])
            : state.counts?.holdPCO,
        holdRock:
          parseInt(action.payload["hold-rock"]) >= 0
            ? parseInt(action.payload["hold-rock"])
            : state.counts?.holdRock ?? 0,
      };
      return {
        ...state,
        counts,
        countStatus: "success",
      };
    }

    case "CONTACTS/ADD_SNACK_DATA": {
      const { entity, data } = action.payload;
      if (entity && entity === "archive-contact") {
        const archivalSnackbarData = { ...state.archivalSnackbarData };
        archivalSnackbarData[data.id] = { ...data };
        return { ...state, archivalSnackbarData };
      } else if (entity && entity === "unarchive-contact") {
        const unarchivalSnackbarData = { ...state.unarchivalSnackbarData };
        unarchivalSnackbarData[data.id] = { ...data };
        return { ...state, unarchivalSnackbarData };
      } else if (entity && entity === "unsubscribe") {
        const unsubscribeSnackbarData = { ...state.unsubscribeSnackbarData };
        unsubscribeSnackbarData[data.id] = { ...data };
        return { ...state, unsubscribeSnackbarData };
      } else if (entity && entity === "subscribe") {
        const subscribeSnackbarData = { ...state.subscribeSnackbarData };
        subscribeSnackbarData[data.id] = { ...data };
        return { ...state, subscribeSnackbarData };
      } else if (entity && entity === "export") {
        const exportContactsSnackbarData = {
          ...state.exportContactsSnackbarData,
        };
        exportContactsSnackbarData[data.id] = { ...data };
        return { ...state, exportContactsSnackbarData };
      } else if (entity && entity === "doNotCall") {
        const doNotCallContactsSnackbarData = {
          ...state.doNotCallContactsSnackbarData,
        };
        doNotCallContactsSnackbarData[data.id] = { ...data };
        return { ...state, doNotCallContactsSnackbarData };
      } else if (entity && entity === "allowCalls") {
        const allowCallsContactsSnackbarData = {
          ...state.allowCallsContactsSnackbarData,
        };
        allowCallsContactsSnackbarData[data.id] = { ...data };
        return { ...state, allowCallsContactsSnackbarData };
      } else if (entity && entity === "markAsFavorite") {
        const markAsFavoriteContactsSnackbarData = {
          ...state.markAsFavoriteContactsSnackbarData,
        };
        markAsFavoriteContactsSnackbarData[data.id] = { ...data };
        return { ...state, markAsFavoriteContactsSnackbarData };
      } else if (entity && entity === "markAsUnFavorite") {
        const markAsUnFavoriteContactsSnackbarData = {
          ...state.markAsUnFavoriteContactsSnackbarData,
        };
        markAsUnFavoriteContactsSnackbarData[data.id] = { ...data };
        return { ...state, markAsUnFavoriteContactsSnackbarData };
      } else if (entity && entity === "block") {
        const blockContactsSnackbarData = {
          ...state.blockContactsSnackbarData,
        };
        blockContactsSnackbarData[data.id] = { ...data };
        return { ...state, blockContactsSnackbarData };
      } else if (entity && entity === "unblock") {
        const unblockContactsSnackbarData = {
          ...state.unblockContactsSnackbarData,
        };
        unblockContactsSnackbarData[data.id] = { ...data };
        return { ...state, unblockContactsSnackbarData };
      } else if (entity && entity === "editGroupsTagsCampaigns") {
        const editGroupsTagsCampaignsSnackbarData = {
          ...state.editGroupsTagsCampaignsSnackbarData,
        };
        editGroupsTagsCampaignsSnackbarData[data.id] = { ...data };
        return { ...state, editGroupsTagsCampaignsSnackbarData };
      } else if (entity && entity === "addTag") {
        const addTagSnackbarData = {
          ...state.addTagSnackbarData,
        };
        addTagSnackbarData[data.id] = { ...data };
        return { ...state, addTagSnackbarData };
      }

      return { ...state };
    }

    case "CONTACTS/REMOVE_SNACK_DATA": {
      const { entity, id } = action.payload;
      if (entity && entity === "archive-contact") {
        const archivalSnackbarData = { ...state.archivalSnackbarData };
        delete archivalSnackbarData[id];
        return { ...state, archivalSnackbarData };
      } else if (entity && entity === "unarchive-contact") {
        const unarchivalSnackbarData = { ...state.unarchivalSnackbarData };
        delete unarchivalSnackbarData[id];
        return { ...state, unarchivalSnackbarData };
      } else if (entity && entity === "unsubscribe") {
        const unsubscribeSnackbarData = { ...state.unsubscribeSnackbarData };
        delete unsubscribeSnackbarData[id];
        return { ...state, unsubscribeSnackbarData };
      } else if (entity && entity === "subscribe") {
        const subscribeSnackbarData = { ...state.subscribeSnackbarData };
        delete subscribeSnackbarData[id];
        return { ...state, subscribeSnackbarData };
      } else if (entity && entity === "export") {
        const exportContactsSnackbarData = {
          ...state.exportContactsSnackbarData,
        };
        delete exportContactsSnackbarData[id];
        return { ...state, exportContactsSnackbarData };
      } else if (entity && entity === "doNotCall") {
        const doNotCallContactsSnackbarData = {
          ...state.doNotCallContactsSnackbarData,
        };
        delete doNotCallContactsSnackbarData[id];
        return { ...state, doNotCallContactsSnackbarData };
      } else if (entity && entity === "allowCalls") {
        const allowCallsContactsSnackbarData = {
          ...state.allowCallsContactsSnackbarData,
        };
        delete allowCallsContactsSnackbarData[id];
        return { ...state, allowCallsContactsSnackbarData };
      } else if (entity && entity === "markAsFavorite") {
        const markAsFavoriteContactsSnackbarData = {
          ...state.markAsFavoriteContactsSnackbarData,
        };
        delete markAsFavoriteContactsSnackbarData[id];
        return { ...state, markAsFavoriteContactsSnackbarData };
      } else if (entity && entity === "markAsUnFavorite") {
        const markAsUnFavoriteContactsSnackbarData = {
          ...state.markAsUnFavoriteContactsSnackbarData,
        };
        delete markAsUnFavoriteContactsSnackbarData[id];
        return { ...state, markAsUnFavoriteContactsSnackbarData };
      } else if (entity && entity === "block") {
        const blockContactsSnackbarData = {
          ...state.blockContactsSnackbarData,
        };
        delete blockContactsSnackbarData[id];
        return { ...state, blockContactsSnackbarData };
      } else if (entity && entity === "unblock") {
        const unblockContactsSnackbarData = {
          ...state.unblockContactsSnackbarData,
        };
        delete unblockContactsSnackbarData[id];
        return { ...state, unblockContactsSnackbarData };
      } else if (entity && entity === "editGroupsTagsCampaigns") {
        const editGroupsTagsCampaignsSnackbarData = {
          ...state.editGroupsTagsCampaignsSnackbarData,
        };
        delete editGroupsTagsCampaignsSnackbarData[id];
        return { ...state, editGroupsTagsCampaignsSnackbarData };
      } else if (entity && entity === "addTag") {
        const addTagSnackbarData = {
          ...state.addTagSnackbarData,
        };
        delete addTagSnackbarData[id];
        return { ...state, addTagSnackbarData };
      }

      return { ...state };
    }

    case "CONTACTS/UNSUBSCRIBE_CONTACTS": {
      return {
        ...state,
        unsubscribeContactsStatus: "loading",
      };
    }

    case "CONTACTS/UNSUBSCRIBE_CONTACTS.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.payload.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setUnsubscribeContact(dataContact);
        setUnsubscribeContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      let newFilteredContacts = state.filteredContacts;
      if (action.isUndo) {
        newFilteredContacts = handleShowFilterItems(
          state.filteredContacts,
          action.payload.contactIds,
          false,
          state.filter,
          "unsubscribed"
        );
      }

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
        contact: newContact,
        unsubscribeContactsStatus: "success",
      };
    }

    case "CONTACTS/UNSUBSCRIBE_CONTACTS.FAILURE": {
      return {
        ...state,
        unsubscribeContactsStatus: "failure",
      };
    }
    case "CONTACTS/UNSUBSCRIBE_CONTACTS.CLEAR": {
      return {
        ...state,
        unsubscribeContactsStatus: null,
      };
    }

    case "CONTACTS/SUBSCRIBE_CONTACTS": {
      return {
        ...state,
        subscribeContactsStatus: "loading",
      };
    }

    case "CONTACTS/SUBSCRIBE_CONTACTS.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.payload.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setSubscribeContact(dataContact);
        setSubscribeContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      const newFilteredContacts = handleShowFilterItems(
        state.filteredContacts,
        action.payload.contactIds,
        true,
        state.filter,
        "unsubscribed"
      );

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
        contact: newContact,
        subscribeContactsStatus: "success",
      };
    }

    case "CONTACTS/SUBSCRIBE_CONTACTS.FAILURE": {
      return {
        ...state,
        subscribeContactsStatus: "failure",
      };
    }

    case "CONTACTS/SUBSCRIBE_CONTACTS.CLEAR": {
      return {
        ...state,
        subscribeContactsStatus: null,
      };
    }

    case "CONTACTS/EXPORT_CONTACTS": {
      return {
        ...state,
        exportContactsStatus: {
          ...state.exportContactsStatus,
          [action.statusId]: "loading",
        },
      };
    }

    case "CONTACTS/EXPORT_CONTACTS.SUCCESS": {
      return {
        ...state,
        exportContactsStatus: {
          ...state.exportContactsStatus,
          [action.statusId]: "success",
        },
        exportContactsCSV: {
          ...state.exportContactsCSV,
          [action.statusId]: action.payload,
        },
      };
    }

    case "CONTACTS/EXPORT_CONTACTS.FAILURE": {
      return {
        ...state,
        exportContactsStatus: {
          ...state.exportContactsStatus,
          [action.statusId]: "success",
        },
      };
    }

    case "CONTACTS/EXPORT_CONTACTS.CLEAR": {
      const exportContactsStatusCopy = { ...state.exportContactsStatus };
      const exportContactsCSVCopy = { ...state.exportContactsCSV };

      delete exportContactsStatusCopy[action.id];
      delete exportContactsCSVCopy[action.id];

      return {
        ...state,
        exportContactsStatus: exportContactsStatusCopy,
        exportContactsCSV: exportContactsCSVCopy,
      };
    }

    case "CONTACTS/DONOTCALL_CONTACTS": {
      return {
        ...state,
        doNotCallContactsStatus: "loading",
      };
    }

    case "CONTACTS/DONOTCALL_CONTACTS.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.payload.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setDoNotCallContact(dataContact);
        setDoNotCallContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      let newFilteredContacts = state.filteredContacts;
      if (action.isUndo) {
        newFilteredContacts = handleShowFilterItems(
          state.filteredContacts,
          action.payload.contactIds,
          false,
          state.filter,
          "do-not-call"
        );
      }

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
        contact: newContact,
        doNotCallContactsStatus: "success",
      };
    }

    case "CONTACTS/DONOTCALL_CONTACTS.FAILURE": {
      return {
        ...state,
        doNotCallContactsStatus: "failure",
      };
    }

    case "CONTACTS/DONOTCALL_CONTACTS.CLEAR": {
      return {
        ...state,
        doNotCallContactsStatus: null,
      };
    }

    case "CONTACTS/TOGGLE_DELETE_CONTACT_MODAL": {
      return {
        ...state,
        isDeleteContactModal: action.payload.value,
        contactToDelete: !action.payload.value
          ? {}
          : action.payload.contactToDelete,
        deleteStatus: !action.payload.value ? null : state.deleteStatus,
        isBatchDelete: !action.payload.value
          ? null
          : action.payload.isBatchDelete,
        deleteContactIds: !action.payload.value
          ? []
          : action.payload.deleteContactIds,
        batchDeleteStatus: !action.payload.value
          ? null
          : state.batchDeleteStatus,
      };
    }

    case "CONTACTS/RESET_DONOTCALL_CONTACTS": {
      return {
        ...state,
        resetDoNotCallContactsStatus: "loading",
      };
    }

    case "CONTACTS/RESET_DONOTCALL_CONTACTS.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.payload.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setResetDoNotCallContact(dataContact);
        setResetDoNotCallContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      const newFilteredContacts = handleShowFilterItems(
        state.filteredContacts,
        action.payload.contactIds,
        true,
        state.filter,
        "do-not-call"
      );

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
        contact: newContact,
        resetDoNotCallContactsStatus: "success",
      };
    }

    case "CONTACTS/RESET_DONOTCALL_CONTACTS.FAILURE": {
      return {
        ...state,
        resetDoNotCallContactsStatus: "failure",
      };
    }
    case "CONTACTS/RESET_DONOTCALL_CONTACTS.CLEAR": {
      return {
        ...state,
        resetDoNotCallContactsStatus: null,
      };
    }

    case "CONTACTS/BLOCK_CONTACTS": {
      return {
        ...state,
        blockContactsStatus: "loading",
      };
    }

    case "CONTACTS/BLOCK_CONTACTS.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.payload.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setBlockedContact(dataContact);
        setBlockedContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      const { contactIds, filteredContactsIds } = deleteRestoreBasedOnAction(
        state,
        action.payload.contactIds,
        action.isUndo,
        "block",
        false
      );

      return {
        ...state,
        filteredContacts: filteredContactsIds,
        contacts: contactIds,
        data: newData,
        contact: newContact,
        blockContactsStatus: "success",
      };
    }

    case "CONTACTS/BLOCK_CONTACTS.FAILURE": {
      return {
        ...state,
        blockContactsStatus: "failure",
      };
    }

    case "CONTACTS/UNBLOCK_CONTACTS": {
      return {
        ...state,
        unblockContactsStatus: "loading",
      };
    }

    case "CONTACTS/UNBLOCK_CONTACTS.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.payload.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };
        setUnlockedContact(dataContact);
        setUnlockedContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      const { contactIds, filteredContactsIds } = deleteRestoreBasedOnAction(
        state,
        action.payload.contactIds,
        action.isUndo,
        "block",
        false
      );

      return {
        ...state,
        filteredContacts: filteredContactsIds,
        contacts: contactIds,
        data: newData,
        contact: newContact,
        unblockContactsStatus: "success",
      };
    }

    case "CONTACTS/UNBLOCK_CONTACTS.FAILURE": {
      return {
        ...state,
        unblockContactsStatus: "failure",
      };
    }

    case "CONTACTS/COUNTS.FAILURE":
      return {
        ...state,
        countStatus: "failure",
      };

    case "CONTACTS/ADD_CONTACTS_FOR_NUMBER_LOOK_UP": {
      return {
        ...state,
        contactIdsNumberLookUp: action.contactIds,
      };
    }

    case "CONTACTS/CONTACTS_FOR_NUMBER_LOOK_UP.CLEAR": {
      return {
        ...state,
        contactIdsNumberLookUp: null,
      };
    }

    case "CONTACTS/SHOW_CONTACTS_DETAIL": {
      return {
        ...state,
        showDetail: !state.showDetail,
      };
    }

    case "FETCH_THREADS_SUCCESS":
    case "THREADS/TEMP.SUCCESS":
    case "FETCH_INCOMING_THREAD_SUCCESS":
    case "THREADS/UNREAD.SUCCESS":
    case "THREADS/UNDELIVERED.SUCCESS":
    case "THREADS/FILTERED.SUCCESS":
    case "THREADS/SPECIAL.SUCCESS":
    case "THREADS_SEARCH_RECEIVERS_SUCCESS":
    case "THREADS/VOICE_THREADS.SUCCESS":
    case "THREADS/VOICE_FILTERED.SUCCESS":
    case "GROUPS/MEMBERS.SUCCESS":
    case "GROUPS/FILTERED_MEMBERS.SUCCESS":
      return {
        ...state,
        data: { ...state.data, ...action.payload.contacts },
        dataStatus: {
          ...state.dataStatus,
          ...fromEntries(
            new Map(
              Object.keys(action.payload.contacts).map((item, index) => {
                return [index, "success"];
              })
            )
          ),
        },
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      resetFetchContactsRequests();
      return initialState;

    // Add Tag
    case "CONTACT/ADD_TAG":
      return {
        ...state,
        addTagStatus: "loading",
        addedTag: null,
      };

    case "CONTACTS/ADD_TAG.SUCCESS":
      return {
        ...state,
        addTagStatus: "success",
        addedTag: action.payload,
        tags: [action.payload],
      };

    case "CONTACTS/ADD_TAG.ERROR":
      return {
        ...state,
        addTagStatus: "error",
      };

    case "CONTACTS/ADD_TAG.CLEAR":
      return {
        ...state,
        addTagStatus: null,
        addedTag: null,
      };
    case "CONTACTS/MARK_AS_FAVORITE": {
      return {
        ...state,
        markAsFavoriteStatus: "loading",
      };
    }

    case "CONTACTS/MARK_AS_FAVORITE.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setMarkAsFavoriteContact(dataContact);
        setMarkAsFavoriteContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      const newFilteredContacts = handleShowFilterItems(
        state.filteredContacts,
        action.contactIds,
        true,
        state.filter,
        "favorites"
      );

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
        contact: newContact,
        markAsFavoriteStatus: "success",
      };
    }

    case "CONTACTS/MARK_AS_FAVORITE.FAILURE": {
      return {
        ...state,
        markAsFavoriteStatus: "failure",
      };
    }
    case "CONTACTS/MARK_AS_FAVORITE.CLEAR": {
      return {
        ...state,
        markAsFavoriteStatus: null,
      };
    }

    case "CONTACTS/MARK_AS_UNFAVORITE": {
      return {
        ...state,
        markAsUnfavoriteStatus: "loading",
      };
    }

    case "CONTACTS/MARK_AS_UNFAVORITE.SUCCESS": {
      const newData = { ...state.data };
      const newContact = { ...state.contact };

      for (let contactId of action.contactIds) {
        const dataContact = { ...newData[contactId] };
        const contactContact = { ...newContact[contactId] };

        setMarkAsUnfavoriteContact(dataContact);
        setMarkAsUnfavoriteContact(contactContact);

        newData[contactId] = dataContact;
        newContact[contactId] = contactContact;
      }

      const newFilteredContacts = handleShowFilterItems(
        state.filteredContacts,
        action.contactIds,
        true,
        state.filter,
        "un-favorites"
      );

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
        contact: newContact,
        markAsUnfavoriteStatus: "success",
      };
    }

    case "CONTACTS/MARK_AS_UNFAVORITE.FAILURE": {
      return {
        ...state,
        markAsUnfavoriteStatus: "failure",
      };
    }
    case "CONTACTS/MARK_AS_UNFAVORITE.CLEAR": {
      return {
        ...state,
        markAsUnfavoriteStatus: null,
      };
    }

    case "CONTACTS/MARK_AS_READ_UNRESOLVED_CONFLICT": {
      return {
        ...state,
        markAsReadUnresolvedConflictsStatus: "",
      };
    }

    case "CONTACTS/MARK_AS_READ_UNRESOLVED_CONFLICT.SUCCESS": {
      const newData = { ...state.data };
      newData[action.contactId].is_hold_contact_read = 1;

      return {
        ...state,
        data: newData,
        markAsReadUnresolvedConflictsStatus: "success",
      };
    }

    case "CONTACTS/MARK_AS_READ_UNRESOLVED_CONFLICT.ERROR": {
      return {
        ...state,
        markAsReadUnresolvedConflictsStatus: "error",
      };
    }

    case "CONTACTS/MARK_AS_READ_UNRESOLVED_CONFLICT.CLEAR": {
      return {
        ...state,
        markAsReadUnresolvedConflictsStatus: "",
      };
    }

    case "CONTACTS/ADD_QUICK.CLEAR": {
      return {
        ...state,
        addQuickStatus: null,
        addQuickError: null,
        addQuickErrorStatus: null,
        addQuickId: null,
      };
    }

    case "SMART_SYNC/MERGE_CONTACT.SUCCESS":
    case "SMART_SYNC/AUTO_MERGE.SUCCESS":
    case "SMART_SYNC/POST_REJECT.SUCCESS": {
      let newFilteredContacts = state.filteredContacts;
      let newData = { ...state.data };

      for (let id of action.contactIds) {
        newFilteredContacts = newFilteredContacts.filter(
          (idAux) => Number(idAux) !== Number(id)
        );
        if (newData[id]) newData[id].is_hold_contact_read = 1;
      }

      return {
        ...state,
        filteredContacts: newFilteredContacts,
        data: newData,
      };
    }

    case "CONTACTS/BATCH_EDIT_TAGS": {
      return {
        ...state,
        batchEditTagsStatus: "loading",
      };
    }
    case "CONTACTS/BATCH_EDIT_TAGS.SUCCESS": {
      return {
        ...state,
        batchEditTagsStatus: "success",
      };
    }
    case "CONTACTS/BATCH_EDIT_TAGS.ERROR": {
      return {
        ...state,
        batchEditTagsStatus: "error",
      };
    }
    case "CONTACTS/BATCH_EDIT_TAGS.CLEAR": {
      return {
        ...state,
        batchEditTagsStatus: "",
      };
    }

    default:
      return state;
  }
}
