import React, { useState, useCallback, useRef, useMemo } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Scrollbars from "react-custom-scrollbars";

//new icons
import { ReactComponent as FaceBookIcon } from "../../../assets/img/icons-new/general/social-media/facebook/facebook-black.svg";
import { ReactComponent as HelpIcon } from "../../../assets/img/icons-new/number-status/show-info/show-info-black.svg";
import { ReactComponent as IntercomIcon } from "../../../assets/img/icons-new/chat/chat-black.svg";
import { ReactComponent as DropDownArrow } from "../../../assets/img/icons-new/general/arrow/select-drop-down-gray.svg";

import Avatar from "../../Avatar";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { logout } from "../../../actions/users";
import UserCreditBalance from "./UserCreditBalance";
import UserPhoneNumbers from "./UserPhoneNumbers";
import DroprightSettingsMenu from "./DroprightSettingsMenu";
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  getFormattedAccountCredits,
  getUserDefaultNumber,
  getUserFullname,
} from "../../../helpers";
import { getUserRoleType } from "../../../utils/settingsHelpers";
import CompanyDetails from "./CompanyDetails";
import ThemeMenu from "../../elements/ThemeMenu";
import LaunchIntercom from "../../hub/HelperComponents/LaunchIntercom";
import useWindowSize from "../../../utils/hooks/useWindowSize";
import SpanWithTooltip from "../../SpanWithTooltip";
import TooltipWhenOverflow from "../../hub/HelperComponents/TooltipWhenOverflow";

function mapStateToProps(store) {
  const userAvatar =
    store.userSettings.avatarUrl || store.users.loggedUser.avatar;
  const companyLogo =
    store.adminSettings.companySettings?.info?.logo ||
    store.companies.currentCompany.logo;
  return {
    loggedUser: store.users.loggedUser,
    currentCompany: store.companies.currentCompany,
    userAvatarUrl: userAvatar,
    companyInfo: companyLogo,
    numbers: store.numbers.numbers,
    credits: store.users.credits,
    assignedNumber: getUserDefaultNumber(store.numbers.numbers),
    senderNumber: store.numbers.senderNumber,
    userPermissions: store.users.permissions,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}
const SidebarSettingsDropright = (props) => {
  const {
    // Component props
    isSidebarExpanded,

    // Redux Functions
    logout,

    //Redux obj props
    assignedNumber,
    loggedUser,
    currentCompany,
    numbers,
    credits,
    userAvatarUrl,
    companyInfo,
    senderNumber,
    userPermissions,
  } = props;

  /* Local States */
  const [dropdownOpen, setOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  /* Refs */
  const timerRef = useRef(null);

  const { visualViewportHeight } = useWindowSize();

  const handleMouseLeave = useCallback(() => {
    if (!buttonClicked) {
      timerRef.current = setTimeout(() => {
        setOpen(false);
      }, 750);
    }
  }, [buttonClicked]);

  const handleHover = () => {
    clearTimeout(timerRef.current);
  };

  const socialAndHelpTab = useMemo(() => {
    return (
      <>
        <a
          href="https://help.pastorsline.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="help-icon">
            <HelpIcon height={18} width={18} className="mb-1" /> Help
          </span>
        </a>
        <LaunchIntercom>
          <IntercomIcon className="mr-1" id="intercom" />
          Chat
        </LaunchIntercom>
        <a
          href="https://www.facebook.com/groups/pastorsline/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="facebook-icon">
            <FaceBookIcon height={18} width={18} className="mb-1" /> Community
          </span>
        </a>
      </>
    );
  }, []);

  const logoutTab = useMemo(() => {
    return (
      <button type="button" className="btn btn-primary mt-2" onClick={logout}>
        Sign Out
      </button>
    );
  }, [logout]);

  const toggle = useCallback(() => {
    if (buttonClicked && setOpen) {
      setOpen(false);
      setButtonClicked(false);
    }
  }, [buttonClicked]);

  const renderPopup = useCallback(() => {
    if (!buttonClicked) {
      setOpen(true);
      setButtonClicked(!buttonClicked);
    } else {
      setOpen(!dropdownOpen);
      setButtonClicked(!buttonClicked);
    }
  }, [buttonClicked, dropdownOpen]);

  const getScrollBarMaxHeight = useMemo(() => {
    if (visualViewportHeight < 700 && visualViewportHeight > 560) {
      return "500px";
    } else if (visualViewportHeight < 560) {
      return "430px";
    }
    return "100%";
  }, [visualViewportHeight]);

  const closePopup = useCallback(() => {
    setOpen(false);
    setButtonClicked(false);
  }, []);

  const isSharedNumber =
    loggedUser &&
    loggedUser.is_shares_number > 0 &&
    currentCompany &&
    currentCompany.is_main_owner > 0;

  const companyName = currentCompany
    ? currentCompany.company_name
    : "Choose organization";

  const availableCredits = useMemo(() => {
    let currentCredits = {};
    if (credits !== null) {
      currentCredits = getFormattedAccountCredits(credits);
    }
    return currentCredits;
  }, [credits]);

  const userFullName = getUserFullname(loggedUser);

  return (
    <ButtonDropdown
      className={clsx("main-sidebar-settings-dropright", {
        minimized: !isSidebarExpanded,
      })}
      direction="left"
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={() => {
        clearTimeout(timerRef.current);
        if (!dropdownOpen) setOpen(true);
      }}
    >
      <div
        className={clsx("main-sidebar-user-profile", {
          minimized: !isSidebarExpanded,
        })}
      >
        <div className="d-flex flex-row">
          <div className="user-img-container">
            <Avatar
              headerMode={true}
              firstName={loggedUser.first_name}
              lastName={loggedUser.last_name}
              email={loggedUser.email}
              className={`user-img ${
                userAvatarUrl
                  ? "sidebar-avatar-wrapper"
                  : "user-dropright-avatar"
              }`}
              size={15}
              avatarUrl={userAvatarUrl}
            />
          </div>

          <div
            className={clsx("user-details", { minimized: !isSidebarExpanded })}
          >
            <label
              className={clsx("full-name mb-0", {
                minimized: !isSidebarExpanded,
              })}
            >
              {userFullName}
            </label>
            <div
              className={clsx("other-details", {
                minimized: !isSidebarExpanded,
              })}
            >
              <div>{capitalizeFirstLetter(companyName)}</div>
              <div>{formatPhoneNumber(assignedNumber)}</div>
              <div>{availableCredits?.creditsFull}</div>
            </div>
          </div>
          <div
            className={clsx("expand-profile-icon", {
              minimized: !isSidebarExpanded,
            })}
          >
            <DropDownArrow />
          </div>
        </div>

        <DropdownToggle
          className={clsx("button-setting d-flex align-items-center", {
            "sidebar-expanded-btn-setting": isSidebarExpanded,
          })}
          onClick={renderPopup}
        ></DropdownToggle>
        <DropdownMenu
          className={clsx("sidebar-dropdown-menu", {
            "sidebar-expanded-dropdown-menu": isSidebarExpanded,
          })}
          onMouseEnter={handleHover}
        >
          <Scrollbars autoHeight autoHeightMax={getScrollBarMaxHeight}>
            {/* User general details tab */}
            <DropdownItem className="account-info">
              <div className="name-title">
                <div className="d-flex flex-row align-items-center">
                  <Avatar
                    className={"user-avatar"}
                    firstName={loggedUser.first_name}
                    lastName={loggedUser.last_name}
                    email={loggedUser.email}
                    avatarUrl={userAvatarUrl}
                  />
                  <TooltipWhenOverflow maxWidth={200} text={userFullName}>
                    <span className="name ml-2">{userFullName}</span>
                  </TooltipWhenOverflow>
                </div>

                <div className="post">
                  {getUserRoleType(loggedUser?.roles[currentCompany.id])}
                </div>
                <Link
                  className="edit"
                  to="/hub/menus/filter/settings/user-settings/submenu/user-profile"
                >
                  <SpanWithTooltip title={"View Profile"} arrow={true}>
                    View or Edit
                  </SpanWithTooltip>
                </Link>
              </div>
              {/* User current company details */}
              <CompanyDetails
                companyInfo={companyInfo}
                companyName={companyName}
              />

              {assignedNumber && (
                <UserPhoneNumbers
                  assignedNumber={assignedNumber}
                  isSharedNumber={isSharedNumber}
                  numbers={numbers}
                  senderNumber={senderNumber}
                />
              )}

              <UserCreditBalance
                userCredits={credits}
                closePopup={closePopup}
              />
            </DropdownItem>

            {/* Theme menu */}
            <DropdownItem className="menuitem-wrapper">
              <ThemeMenu />
            </DropdownItem>

            {/* Settings menu */}
            <DropdownItem className="menuitem-wrapper admin-user-menus-container">
              <DroprightSettingsMenu
                currentCompany={currentCompany}
                userPermissions={userPermissions}
                closePopup={closePopup}
              />
            </DropdownItem>

            {/*Social and pastorsline help tab */}
            <DropdownItem className="menuitem-wrapper">
              <span className="menuitem social-menu-container d-flex align-items-center justify-content-between">
                {socialAndHelpTab}
              </span>
            </DropdownItem>

            {/*Bottom logout tab */}
            <DropdownItem className="menuitem-wrapper last-item">
              {logoutTab}
            </DropdownItem>
          </Scrollbars>
        </DropdownMenu>
      </div>
    </ButtonDropdown>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarSettingsDropright);
