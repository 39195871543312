import { useCallback, useState } from "react";
import useMainStore from "./ReduxHooks/mainStore";
import { isChrome } from "../extensionHelpers";

export const useMicrophonePermissions = () => {
  // Local state
  const [withoutMicrophonePermissions, setWithoutMicrophonePermissions] =
    useState(false);

  // Redux store
  const { setUnsupportedMicrophonePermissionModalData } = useMainStore();

  const checkMicrophonePermissions = useCallback(async () => {
    try {
      // Check existing microphone permissions
      if (isChrome()) {
        const permissions = await navigator.permissions.query({
          name: "microphone",
        });
        if (permissions.state === "granted") {
          setUnsupportedMicrophonePermissionModalData({ show: false });
          setWithoutMicrophonePermissions(false);
          return;
        } else if (permissions.state === "denied") {
          setWithoutMicrophonePermissions(true);
          setUnsupportedMicrophonePermissionModalData({ show: true });
          return;
        }
      }

      // If permission is not granted or denied, request permissions
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setUnsupportedMicrophonePermissionModalData({ show: false });
      setWithoutMicrophonePermissions(false);
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setWithoutMicrophonePermissions(true);
      setUnsupportedMicrophonePermissionModalData({ show: true });
    }
  }, [setUnsupportedMicrophonePermissionModalData]);

  return {
    checkMicrophonePermissions,
    withoutMicrophonePermissions,
  };
};
