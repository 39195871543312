import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ReactComponent as AlertSvgWhite } from "../../../../assets/img/icons-new/number-status/show-info/show-info-white.svg";
import useUsersStore from "../../../../utils/hooks/ReduxHooks/userStore";
import useCompaniesStore from "../../../../utils/hooks/ReduxHooks/companiesStore";
import { useIsAdminOrOwner } from "../../../../utils/hooks/useIsAdminOrOwner";

const OutOfCreditAlert = (props) => {
  const { onClickBtn } = props;
  const {
    companies: { currentCompany },
  } = useCompaniesStore();
  const {
    users: { creditsSummary },
  } = useUsersStore();
  // router
  const history = useHistory();

  // Custom hook
  const isAdminOrOwner = useIsAdminOrOwner(currentCompany);

  const onCreditOptionsClick = useCallback(
    (type) => () => {
      let url = "/hub/menus/filter/settings/admin-settings/submenu/";
      url +=
        type === "purchase"
          ? "admin-billing/billing"
          : "admin-users/active-users";
      history.push(url);
      onClickBtn();
    },
    [history, onClickBtn]
  );

  return (
    <div className="alert top-alert align-items-center info m-3">
      <AlertSvgWhite width={20} height={20} />
      <span className="ml-2">
        You are out of credits.{" "}
        {isAdminOrOwner ? (
          <>
            You can either <br />
            <a href="#void" onClick={onCreditOptionsClick("purchase")}>
              <u>Purchase More Credits</u>
            </a>{" "}
            or{" "}
            <a href="#void" onClick={onCreditOptionsClick("user-credit")}>
              <u>View/Adjust Other Users' Credits</u>
            </a>{" "}
            ({creditsSummary?.users_assigned_credits || 0} Assigned to Users)
          </>
        ) : (
          <> Please contact your admin</>
        )}
      </span>
    </div>
  );
};

OutOfCreditAlert.propTypes = {
  onClickBtn: PropTypes.func,
};

OutOfCreditAlert.defaultProps = {
  onClickBtn: () => {},
};

export default OutOfCreditAlert;
