const initialState = {
  ready: false,
  unsupportedBrowser: false,

  callStatus: false,
  callFrom: null,
  callContactId: null,
  callMinutePrice: null,
  callStartedAt: null,

  callTtvStatus: null,
  callTtvFrom: null,
  callTtvText: null,
  callTtvLanguage: null,
  callTtvVoice: null,

  // Incoming call status
  incomingCallStatus: null,
  incomingCallParams: null,

  // OUTGOING call status
  outgoingCallParams: null,
};

export default function reducer(state = initialState, action) {
  let callStartedAt = null;
  switch (action.type) {
    case "TWILIO/READY":
      return {
        ...state,
        ready: action.ready,
      };

    case "TWILIO/UNSUPPORTED_BROWSER":
      return {
        ...state,
        unsupportedBrowser: true,
      };

    case "TWILIO/CALL":
      callStartedAt = state.callStartedAt;
      if (action.status !== "connected") {
        callStartedAt = null;
      } else if (callStartedAt === null) {
        callStartedAt = new Date().getTime();
      }
      return {
        ...state,
        callStatus: action.status,
        callFrom: action.from,
        callContactId: action.contactId,
        callMinutePrice: action.minutePrice,
        callStartedAt: callStartedAt,
      };

    case "TWILIO/CALL_STATUS":
      callStartedAt = state.callStartedAt;
      if (action.status !== "connected") {
        callStartedAt = null;
      } else if (callStartedAt === null) {
        callStartedAt = new Date().getTime();
      }
      return {
        ...state,
        callStatus: action.status,
        callStartedAt: callStartedAt,
      };

    case "TWILIO/CALL_TTV":
      return {
        ...state,
        callTtvStatus: action.status,
        callTtvFrom: action.from,
        callTtvText: action.text,
        callTtvLanguage: action.language,
        callTtvVoice: action.voice,
      };

    case "TWILIO/CALL_TTV_STATUS":
      return {
        ...state,
        callTtvStatus: action.status,
      };

    case "TWILIO/INCOMING_CALL_STATUS":
      return {
        ...state,
        incomingCallStatus: action.status,
      };

    case "TWILIO/SET_INCOMING_CALLS_PARAMETER":
      return {
        ...state,
        incomingCallParams: action.params,
      };

    case "TWILIO/SET_OUTGOING_CALLS_PARAMETER":
      return {
        ...state,
        outgoingCallParams: action.params,
      };

    // Clear data after logout and switch company
    case "USER_LOGOUT":
    case "SET_CURRENT_COMPANY":
      return initialState;

    default:
      return state;
  }
}
