import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ReactComponent as NumberStatusLightSvg } from "../../../../assets/img/icons-new/people-tab/number-status/number-status-light-gray.svg";
import usePrevious from "../../../../utils/hooks/usePrevious";
import SpanWithTooltip from "../../../SpanWithTooltip";

const CreditEstimate = (props) => {
  const {
    credits,
    unicodeCharacters,
    characters,
    mmsCount,
    smsCount,
    subscribersCount,
    hideText,
    swapForIcon,
    isTollFreeNumberSelectedAsSenderNumber,
  } = props;

  const creditsPrevious = usePrevious(credits);
  const timeOutInterval = useRef();
  const [containerBlinkClassName, setContainerBlinkClassName] = useState("");
  const [creditsWrapperBlinkClassName, setCreditsWrapperBlinkClassName] =
    useState("");

  const resetBlinkClassNames = useCallback(() => {
    setContainerBlinkClassName("");
    setCreditsWrapperBlinkClassName("");
  }, []);

  const handleBlinkClassNames = useCallback(
    (color) => {
      clearTimeout(timeOutInterval.current);
      setContainerBlinkClassName(`blink-${color}-color`);
      setCreditsWrapperBlinkClassName(`blink-${color}-background`);
      timeOutInterval.current = setTimeout(() => resetBlinkClassNames(), 2000);
    },
    [resetBlinkClassNames]
  );

  useEffect(() => {
    if (
      credits !== 0 &&
      credits !== creditsPrevious &&
      unicodeCharacters === 0
    ) {
      handleBlinkClassNames("purple");
    } else if (
      credits !== 0 &&
      credits !== creditsPrevious &&
      unicodeCharacters > 0
    ) {
      handleBlinkClassNames("orange");
    }
  }, [credits, creditsPrevious, handleBlinkClassNames, unicodeCharacters]);

  const statsTooltipProps = {
    title: (
      <div style={{ lineHeight: 1.8, fontSize: "0.7rem" }}>
        Characters: {characters}
        <br />
        {unicodeCharacters > 0 ? (
          <>
            <span class="warning">Unicode: {unicodeCharacters}</span>
            <br />
          </>
        ) : (
          <></>
        )}
        Subscriber(s): {subscribersCount}
        <br />
        MMS: {mmsCount}{" "}
        {isTollFreeNumberSelectedAsSenderNumber ? `(2.5/subs)` : `(2/subs)`}
        <br />
        SMS parts: {smsCount}/10
      </div>
    ),
  };

  return (
    <div
      className={clsx(
        { "credits-container": swapForIcon === null },
        containerBlinkClassName
      )}
    >
      {!hideText && (
        <>
          Credit Estimate:
          <span
            className={`credits-number-container ml-1 ${creditsWrapperBlinkClassName}`}
          >
            {credits}
          </span>
        </>
      )}
      {swapForIcon === null ? (
        <SpanWithTooltip {...statsTooltipProps}>
          <NumberStatusLightSvg width={14} height={14} className="ml-1" />
        </SpanWithTooltip>
      ) : (
        <SpanWithTooltip {...statsTooltipProps}>{swapForIcon}</SpanWithTooltip>
      )}
    </div>
  );
};

CreditEstimate.propTypes = {
  credits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unicodeCharacters: PropTypes.number,
  characters: PropTypes.number,
  mmsCount: PropTypes.number,
  smsCount: PropTypes.number,
  subscribersCount: PropTypes.number,
  hideText: PropTypes.bool,
  isTollFreeNumberSelectedAsSenderNumber: PropTypes.bool,
  swapForIcon: PropTypes.any,
};

CreditEstimate.defaultProps = {
  credits: 0,
  unicodeCharacters: 0,
  characters: 0,
  mmsCount: 0,
  smsCount: 0,
  subscribersCount: 1,
  hideText: false,
  isTollFreeNumberSelectedAsSenderNumber: false,
  swapForIcon: null,
};

export default CreditEstimate;
