import React, { useCallback, useEffect, useMemo } from "react";
import clsx from "clsx";
import { ReactComponent as HoldCallSvg } from "../../../assets/img/icons-new/hold-call/hold-call-black.svg";
import PropTypes from "prop-types";
import { useCallsStore } from "../../../utils/hooks/ReduxHooks/callsStore";
import useCompaniesStore from "../../../utils/hooks/ReduxHooks/companiesStore";
import useMainStore from "../../../utils/hooks/ReduxHooks/mainStore";
import { CircularProgress } from "@material-ui/core";

const HoldOrUnHoldCallButton = (props) => {
  const { disabled, className, callSID } = props;

  // Redux store
  const { calls, holdCall, unholdCall, clearHoldCallStatus } = useCallsStore();
  const { companies } = useCompaniesStore();
  const { addInfoSnackBar } = useMainStore();
  const { holdAndUnholdCallApiStatus, isCallOnHold } = calls;
  const { currentCompany } = companies;

  const isLoading = useMemo(
    () => holdAndUnholdCallApiStatus === "loading",
    [holdAndUnholdCallApiStatus]
  );

  useEffect(() => {
    if (holdAndUnholdCallApiStatus === "error") {
      addInfoSnackBar({
        msg: "Please wait for the recipient to answer the call.",
        err: true,
      });
      clearHoldCallStatus();
    }
  }, [clearHoldCallStatus, holdAndUnholdCallApiStatus, addInfoSnackBar]);

  const handleHoldAndUnholdCall = useCallback(() => {
    if (isLoading || disabled) return null;
    const apiData = { companyId: currentCompany?.id, sid: callSID };
    if (isCallOnHold) {
      unholdCall(apiData);
    } else {
      holdCall(apiData);
    }
  }, [
    callSID,
    currentCompany,
    holdCall,
    isCallOnHold,
    unholdCall,
    isLoading,
    disabled,
  ]);

  return (
    <span className="flexer-col">
      <span
        className={clsx("calling-features-wrapper", className, {
          disabled: disabled,
          active: isCallOnHold,
        })}
        onClick={handleHoldAndUnholdCall}
      >
        {isLoading ? (
          <CircularProgress size={30} />
        ) : (
          <HoldCallSvg height={25} width={25} />
        )}
      </span>
      <label className="mt-1">{isCallOnHold ? "Unhold" : "Hold"}</label>
    </span>
  );
};

HoldOrUnHoldCallButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  callSID: PropTypes.string,
};

HoldOrUnHoldCallButton.defaultProps = {
  disabled: false,
  className: "",
  callSID: "",
};

export default HoldOrUnHoldCallButton;
