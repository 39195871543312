import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { isPastorsLineContact, isPastorsLineGroup } from "../../../helpers";
import SpanWithTooltip from "../../SpanWithTooltip";

//new icons
import { ReactComponent as ReopenSVG } from "../../../assets/img/icons-new/message-tab/closed/closed-purple.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/img/icons-new/people-tab/archived/archived-purple.svg";
import { ReactComponent as UnArchiveIcon } from "../../../assets/img/icons-new/people-tab/unarchived/unarchived-purple.svg";
import { ReactComponent as BroadcastIconSvg } from "../../../assets/img/icons-new/message-call-type/broadcast/broadcast-gray.svg";
import { ReactComponent as PeopleGroupSvg } from "../../../assets/img/icons-new/group-tab/group-icon/group-icon-black.svg";
import { ReactComponent as CallIcon } from "../../../assets/img/icons-new/voice-tab/all-voice-message/all-voice-message-mid-gray.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons-new/general/trash/trash-purple.svg";
//missing icon
// import { ReactComponent as ProcessQueuesIconSvg } from "../../../assets/img/icons/process-queues-icon.svg";
// import { ReactComponent as SavedSearchesIconSvg } from "../../../assets/img/icons/saved-searches-icon.svg";
import { ReactComponent as ClosedSVG } from "../../../assets/img/icons/reopen.svg";

import ThreadsActionsArchivedPeople from "../../elements/ThreadsActionsArchivedPeople";
import {
  checkThreadCurrentState,
  getActionMenuGroupArchive,
  getNewCallDialPadActionIcons,
  GetNewContactActionIcons,
  getUnblockedActionIcon,
} from "./threadItemHelper";
import { getPermissionParsed } from "../../../utils/settingsHelpers";
import { FILTERS_TO_SHOW_CLOSE_BUTTON_ON_TEXT_MESSAGE_TAB } from "../../../utils/threadsHelpers";

function ThreadItemActionIcon(props) {
  const {
    showCheckBox,
    tab,
    filter,
    contact,
    onReopenToggle,
    handleArchiveOrClose,
    handleDeleteTag,
    isGroupTag,
    threadFilter,
    group,
    contactFilter,
    archiveFilter,
    contacts,
    closedFilter,
    voiceFilter,
    name,
    isNewGroup,
    isGroup,
    groupName,
    isMultipleContacts,
    purpose,
    handleNewContact,
    newContact,
    isAllFlowsInProgress,
    showNewContactActionTooltip,
    setShowNewContactActionTooltip,
    userPermissions,
    onClickViewHiddenSearchResult,
    thredItemNewContactPermissions,
  } = props;

  const isGroupTab = tab === "groups";
  const isContactTab = tab === "people";
  const isMessageTab = tab === "messages";
  const isCallsTab = tab === "calls";
  const isGroupTabArchivedButNotPastorLine =
    isGroupTab && archiveFilter ? !isPastorsLineGroup(group) : true;
  const isGroupStatusNotBlockedOrUnclassified =
    !group?.my_group_status &&
    !group?.is_unclassified &&
    contactFilter !== "blocked";
  const isContactTabArchivedNotPastorLine =
    isContactTab && archiveFilter
      ? filter === "archived-no-integration"
        ? false
        : !isPastorsLineContact(contact)
      : true;

  const handleActionIconClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (checkThreadCurrentState(checkThreadCurrentState)) {
        return onReopenToggle(true);
      } else {
        if (isGroupTag) {
          return handleDeleteTag(e);
        } else {
          return handleArchiveOrClose(e);
        }
      }
    },
    [handleArchiveOrClose, handleDeleteTag, onReopenToggle, isGroupTag]
  );

  return (
    <>
      {showCheckBox &&
        !isAllFlowsInProgress &&
        (!archiveFilter || contacts) &&
        isGroupTabArchivedButNotPastorLine &&
        isContactTabArchivedNotPastorLine &&
        purpose !== "new-call-modal" && (
          <div className="archive-icon-threaditems">
            <button
              className="archive-btn"
              style={{ marginRight: "-8px" }}
              onClick={handleActionIconClick}
            >
              {isMessageTab || isCallsTab ? (
                <>
                  {isMessageTab &&
                    !closedFilter &&
                    FILTERS_TO_SHOW_CLOSE_BUTTON_ON_TEXT_MESSAGE_TAB.includes(
                      threadFilter
                    ) && (
                      <>
                        <SpanWithTooltip
                          title="Close"
                          placement="top"
                          id="toggleTooltip"
                        >
                          <ReopenSVG width={36} height={36} />
                        </SpanWithTooltip>
                      </>
                    )}

                  {isCallsTab && !closedFilter && !voiceFilter && (
                    <>
                      <SpanWithTooltip
                        placement="top"
                        title="Close"
                        id="toggleTooltip"
                      >
                        <ReopenSVG width={36} height={36} />
                      </SpanWithTooltip>
                    </>
                  )}

                  {closedFilter && (
                    <>
                      <SpanWithTooltip
                        title="Reopen"
                        placement="top"
                        id="toggleToolTip"
                      >
                        <ClosedSVG width={36} height={36} />
                      </SpanWithTooltip>
                    </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {!archiveFilter ? (
                    isGroupStatusNotBlockedOrUnclassified && (
                      <>
                        {isGroupTag &&
                        getPermissionParsed(
                          userPermissions?.people,
                          "delete"
                        ) ? (
                          <SpanWithTooltip
                            title="Delete"
                            placement="top"
                            id="toggleToolTip"
                          >
                            <DeleteIcon />
                          </SpanWithTooltip>
                        ) : (
                          getPermissionParsed(
                            userPermissions?.people,
                            "archive"
                          ) && (
                            <SpanWithTooltip
                              title="Archive"
                              placement="top"
                              id="toggleToolTip"
                            >
                              <ArchiveIcon />
                            </SpanWithTooltip>
                          )
                        )}
                      </>
                    )
                  ) : (
                    <>
                      {getPermissionParsed(
                        userPermissions?.people,
                        "archive"
                      ) && (
                        <SpanWithTooltip
                          title="Unarchive"
                          placement="top"
                          id="toggleToolTip"
                        >
                          <UnArchiveIcon />
                        </SpanWithTooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </button>
          </div>
        )}
      {showCheckBox &&
        !isAllFlowsInProgress &&
        !group &&
        archiveFilter &&
        isContactTab &&
        (filter === "archived-no-integration"
          ? true
          : isPastorsLineContact(contact)) && (
          <div onClick={(event) => event.preventDefault()}>
            <ThreadsActionsArchivedPeople
              contact={contact}
              shouldRedirectArchiveAction={false}
              useUnarchiveModal={true}
            />
          </div>
        )}

      {/* on groups tab, archive filter, show action menu */}
      {getActionMenuGroupArchive({
        showCheckBox,
        isAllFlowsInProgress,
        tab,
        archiveFilter,
        group,
      })}

      {getUnblockedActionIcon({
        showCheckBox,
        isAllFlowsInProgress,
        tab,
        threadFilter,
        voiceFilter,
        contactFilter,
        group,
        contact,
      })}

      {getNewCallDialPadActionIcons({
        name,
        purpose,
      })}

      {GetNewContactActionIcons({
        isNewGroup,
        handleNewContact,
        purpose,
        groupName,
        newContact,
        showNewContactActionTooltip,
        setShowNewContactActionTooltip,
        userPermissions,
        thredItemNewContactPermissions,
      })}

      {purpose === "new-call-modal" && (
        <>
          {isMultipleContacts && (
            <span className="icon ml-2">
              <PeopleGroupSvg
                className={"icon-black"}
                width={18}
                style={{ minWidth: "18px" }}
              />
            </span>
          )}
        </>
      )}
      {!handleNewContact && purpose === "call-modal" && isGroup && (
        <SpanWithTooltip
          className="icon"
          title="Voice Broadcast"
          data-container=".message-single"
        >
          <BroadcastIconSvg width={16} />
        </SpanWithTooltip>
      )}
      {!handleNewContact && purpose === "call-modal" && !isGroup && (
        <SpanWithTooltip title="Make a call">
          <CallIcon />
        </SpanWithTooltip>
      )}
      {onClickViewHiddenSearchResult && (
        <span
          onClick={onClickViewHiddenSearchResult}
          className="view-hidden-result"
        >
          View
        </span>
      )}
    </>
  );
}

ThreadItemActionIcon.propTypes = {
  showCheckBox: PropTypes.bool,
  tab: PropTypes.string,
  filter: PropTypes.string,
  contact: PropTypes.object,
  onReopenToggle: PropTypes.func,
  handleArchiveOrClose: PropTypes.func,
  threadFilter: PropTypes.string,
  closedThreadsIds: PropTypes.object,
  group: PropTypes.object,
  contactFilter: PropTypes.string,
  archiveFilter: PropTypes.string,
  contacts: PropTypes.array,
  closedFilter: PropTypes.string,
  voiceFilter: PropTypes.string,
  name: PropTypes.object,
  isNewGroup: PropTypes.bool,
  isGroup: PropTypes.bool,
  groupName: PropTypes.string,
  isMultipleContacts: PropTypes.bool,
  purpose: PropTypes.string,
  handleNewContact: PropTypes.func,
  newContact: PropTypes.object,
  isAllFlowsInProgress: PropTypes.bool,
  thredItemNewContactPermissions: PropTypes.object,
};

ThreadItemActionIcon.defaultProps = {
  thredItemNewContactPermissions: {
    showAddPersonIcon: true,
    showNewMessageIcon: true,
    showCallIcon: true,
    showNewGroupIcon: true,
  },
};

export default ThreadItemActionIcon;
